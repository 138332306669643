import React from "react";

import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  VStack,
  Image,
  Link,
  Center,
} from "@chakra-ui/react";

import NorbiLogo from "./NorbiLogos";

const MediaCard = ({ src, alt, title, desc, link }) => (
  <Box
    as={Link}
    to={link}
    position="relative"
    borderRadius="20px"
    overflow="hidden"
    p={6}
    border="1px solid #333"
    borderColor="linear-gradient(to bottom, #333333 50%, rgba(255,255,255,0.3) 100%)"
    transition="transform 0.3s ease-in-out"
    _hover={{
      textDecoration: "none",
    }}
    display="block"
  >
    <VStack align="center">
      <Link href={link} isExternal>
        <Image src={src} alt={alt} width="full" objectFit="cover" mb={4} />
        <Center flexDirection="column">
          <Heading size="md" color="#FFF">
            {title}
          </Heading>
          <Text fontSize="md" color="#FFF">
            {desc}
          </Text>
        </Center>
      </Link>
    </VStack>
  </Box>
);

const StyledText = ({
  children,
  color = "white",
  sizes = ["24px", "30px", "24px", "28px", "30px"],
  mb,
}) => (
  <Text fontSize={sizes} fontWeight="bold" color={color} mb={mb}>
    {children}
  </Text>
);

const images = [
  {
    src: "/assets/haber1.png",
    alt: "Ekonomi Dergisi",
    title: "Ekonomi Dergisi",
    desc: "PolyVerse’den, Türkiye’nin Web 3.0 dijital dönüşümünde öncü adım",
    link: "https://www.ekonomigazetesi.com/sehirler/polyverseden-turkiyenin-web-3-0-dijital-donusumunde-oncu-adim-29062/",
  },
  {
    src: "/assets/haber2.png",
    alt: "Para Dergisi",
    title: "Para Dergisi",
    desc: "Denizli’yi teknoloji yatırımında cazibe merkezi yapacak",
    link: "https://www.paradergi.com.tr/teknoloji/2024/01/08/denizliyi-teknoloji-yatiriminda-cazibe-merkezi-yapacak",
  },
  {
    src: "/assets/haber3.png",
    alt: "Capital Dergisi",
    title: "Capital Dergisi",
    desc: "80 milyonun varlıklarını dijital cüzdanımızda saklayacağız",
    link: "/assets/Funika-Medya.pdf",
  },
  {
    src: "/assets/haber2.png",
    alt: "Alem Dergisi",
    title: "Alem Dergisi",
    desc: "Civan Sözkesen ile Başarının Sırrı",
    link: "https://www.alem.com.tr/yasam/civan-sozkesen-ile-basarinin-sirri-1082274",
  },
  {
    src: "/assets/haber5.png",
    alt: "Capital-Digital Money",
    title: "Capital-Digital Money",
    desc: "Türkiye Finansal Devrimde Bölgesel Güç Olacak",
    link: "/assets/capital digital money.pdf",
  },
];

const MediaAndPress = () => {
  return (
    <Box
      bg="transparent"
      py={6}
      mb={20}
      color="white"
      px={{ base: "5%", lg: 32 }}
    >
      <VStack spacing={8}>
        <Box textAlign="center" mb={4} mt={{ base: 20, lg: 0 }}>
          <StyledText color="#9256E7" fontWeight="bold" mb={4}>
            Medya ve Basın
          </StyledText>
          <Text
            fontSize={{
              base: "24px",
              sm: "50px",
              md: "40px",
              lg: "50px",
            }}
            fontWeight="bold"
          >
            Basında Biz
          </Text>
        </Box>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          {images.map((item, index) => (
            <MediaCard
              key={index}
              src={item.src}
              alt={item.alt}
              title={item.title}
              desc={item.desc}
              link={item.link}
            />
          ))}
        </SimpleGrid>
      </VStack>
      <NorbiLogo />
    </Box>
  );
};

export default MediaAndPress;

import React from "react";
import { Box, keyframes } from "@chakra-ui/react";

const bounce = keyframes`
  0%, 80%, 100% { transform: scale(0); } 
  40% { transform: scale(1.0); }
`;

const dotStyle = {
  display: "inline-block",
  width: "12px", // Adjusted width
  height: "12px", // Adjusted height
  margin: "0 4px", // Adjusted margin
  backgroundColor: "white",
  borderRadius: "50%",
  animation: `${bounce} 1.4s infinite ease-in-out both`,
  verticalAlign: "middle", // Ensure dots are vertically aligned
};

const LoadingDots = () => (
  <Box display="flex" alignItems="center" justifyContent="center" height="100%">
    <Box sx={{ ...dotStyle, animationDelay: "0s" }} />
    <Box sx={{ ...dotStyle, animationDelay: "0.2s" }} />
    <Box sx={{ ...dotStyle, animationDelay: "0.4s" }} />
  </Box>
);

export default LoadingDots;

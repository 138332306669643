import React from "react";
import {
  Box,
  Heading,
  Text,
  List,
  ListItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
} from "@chakra-ui/react";

const PolicySection = ({ title, children }) => (
  <Box mt={6}>
    <Heading as="h2" size="md" mb={4}>
      {title}
    </Heading>
    {children}
  </Box>
);

const RiskStatement = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        UYUM RİSKLERİNİN YÖNETİMİNE DAİR RİSK İŞTAHI BİLDİRİSİ
      </Heading>

      <PolicySection title="1. GİRİŞ">
        <Text>
          İşbu Uyum Risklerinin Yönetimine Dair Risk İştahı Bildirisi ("Bildiri"), Yenkido Bilişim Hizmetleri Ticaret Anonim Şirketi'nin ("Norbi") risk iştahına ve toleransına ilişkin yaklaşımı tanımlamaktadır. Risk iştahı yaklaşımı; 5549 Sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanun ("5549 Sayılı Kanun") ve bağlı yasal mevzuat düzenlemelerine uyum, kara paranın aklanması, terörizmin finansmanı, yaptırımlar ve finansal suç risklerine ilişkin diğer tüm alanlarda düzenli olarak gerçekleştirilen risk ölçüm değerlendirmesine göre belirlenir ve periyodik olarak Norbi Yönetim Kurulu ("Yönetim Kurulu") tarafından gözden geçirilir. Norbi dâhilinde gerçekleştirilen tüm faaliyetler işbu Bildiri ile uyumlu olmak zorundadır.
        </Text>
      </PolicySection>

      <PolicySection title="2. RİSK İŞTAHIMIZA GENEL BAKIŞ">
        <Text>
          Norbi uyum risklerinin yönetiminde; mevzuat standartları, profesyonellik, çalışan kalitesi ve paydaşların güvenilirliğini ön planda tutan detaylı süreçlerle çalışmaktadır.
        </Text>
        <Text mt={4}>
          Norbi, yurt içinde ve yurt dışında OFAC, AB, BM ve diğer tüm yaptırım otoriteleriyle uyumlu olacak şekilde katı bir yaptırım politikası ("Yaptırım Politikası") yürütür. Yaptırım Politikası, yaptırım kapsamına alınan tüm gerçek ve tüzel kişilerle Norbi aracılığıyla herhangi bir ilişki kurulumunu yasaklamaktadır. Aynı zamanda global düzeyde ülke ve kuruluşlara ait yaptırım, yasa ve politikalar kapsamında önleyici nitelikte aksiyonlar alır. Yaptırım Politikası, Norbi'nin risk ölçüm değerlendirmesi ve risk iştahının yönetiminde belirleyici bir nitelik taşımaktadır.
        </Text>
        <Text mt={4}>
          Norbi'nin, kara paranın aklanması faaliyetlerinin önlenmesi kapsamında aktif ve önleyici bir yaklaşımı vardır. Müşteri profilleme ve segmente etme çalışmaları ile analitik düzeyde beslenen bu yaklaşım, raporlama ve izleme süreçlerinde de korunmaktadır.
        </Text>
        <Text mt={4}>
          Global uyum düzeninde meydana çıkan yeni trend ve değişen koşullara bağlı olarak, Norbi sadece kara paranın aklanması, yaptırım ve terörizmin finansmanı kapsamında değil yolsuzluk ve rüşvet gibi diğer tüm finansal suç alanlarında da etkin bir yönetim yapısına sahiptir.
        </Text>
      </PolicySection>

      <PolicySection title="3. RİSK YÖNETİMİ ANLAYIŞIMIZ">
        <Text>
          Türkiye, yaptırıma tabi ülkelere olan coğrafi yakınlığı sebebiyle yalnızca yasa ve regülasyonlardan doğan teknik uyum risklerine değil, aynı zamanda söz konusu teknik uyum riskleri nedeniyle ortaya çıkan finansal performans ve itibar riskine de tabi olmakta, bahsedilen tüm bu uyum riskleri stratejik uyum riski kavramını oluşturmaktadır.
        </Text>
        <Text mt={4}>
          Norbi risk yönetim anlayışı, uçtan uca tüm operasyonları kapsayacak etkin bir süreç olarak tasarlanmıştır. Norbi'nin gerçekleştirdiği aktivitelerde bütünleyici bir unsur olan risk yönetimi tüm çalışanlarının sorumluluğudur. Norbi içerisinde özellikle yönetici pozisyonunda olan çalışanların risk yönetimi anlayışının gerçekleştirilmesi, risk çerçevesinin oluşturulması, bu alanda kontroller kurulması ve kontrollerin etkinliğinin test edilmesinde önemli bir sorumluluğu vardır. Risk yönetim kültürü, Norbi'nin iş yapış süreçlerinde, uygun tutum, analiz ve yönetişime vurgu yapmaktadır.
        </Text>
      </PolicySection>

      <PolicySection title="4. KAPSAM">
        <Text>
          Norbi risk iştahı yaklaşımı aşağıdaki tabloda gösterildiği üzere müşteri değer zincirinin farklı aşamalarında belirtilmiştir:
        </Text>
        <List mt={4} spacing={2}>
          <ListItem>• Müşteri Riski</ListItem>
          <ListItem>• Ülke/Bölge Riski</ListItem>
          <ListItem>• Ürün/Hizmet/İşlem Riski</ListItem>
          <ListItem>• Teknoloji/Kanal Riski</ListItem>
        </List>
      </PolicySection>

      <PolicySection title="5. RİSK İŞTAHI SINIFLANDIRMASI">
        <Table variant="simple" mt={4}>
          <Thead>
            <Tr>
              <Th  colSpan={2} color="white">
                Müşteri Riski
              </Th>
              <Th colSpan={2}  color="white">
                Ülke/Bölge Riski
              </Th>
              <Th colSpan={2} color="white">
                Ürün/Hizmet/İşlem Riski
              </Th>
              <Th colSpan={2} color="white">
                Teknoloji/Kanal Riski
              </Th>
            </Tr>
            <Tr>
              <Th  color="white">
                Müşteri Tipi
              </Th>
              <Th  color="white">
                Uygulanan Tedbir
              </Th>
              <Th  color="white">
                Risk Tipi
              </Th>
              <Th color="white">
                Uygulanan Tedbir
              </Th>
              <Th  color="white">
                Risk Tipi
              </Th>
              <Th  color="white">
                Uygulanan Tedbir
              </Th>
              <Th color="white">
                Risk Tipi
              </Th>
              <Th color="white">
                Uygulanan Tedbir
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Yasaklı Kişiler</Td>
              <Td>Yasaklı</Td>
              <Td>1. Derece Yaptırım Uygulanan Ülkeler</Td>
              <Td>Yasaklı</Td>
              <Td>Riskli Bölgelerden Yapılan Transferler</Td>
              <Td>Sıkılaştırılmış</Td>
              <Td>ATM</Td>
              <Td>Yasaklı</Td>
            </Tr>
            <Tr>
              <Td>Uyum Birimi Kara Listesi</Td>
              <Td>Yasaklı</Td>
              <Td>FATF-İşbirliği Yapmayan Ülkeler/Bölgeler</Td>
              <Td>Yasaklı</Td>
              <Td>Riskli Müşterilerle Yapılan Transferler</Td>
              <Td>Sıkılaştırılmış</Td>
              <Td>Fintech</Td>
              <Td>Yasaklı</Td>
            </Tr>
            <Tr>
              <Td>Bahis Şirketleri</Td>
              <Td>Yasaklı</Td>
              <Td>Offshore/Vergi Cennetleri</Td>
              <Td>Yasaklı</Td>
              <Td>Yüksek Nakit İşlemler</Td>
              <Td>Sıkılaştırılmış</Td>
              <Td>Blockchain</Td>
              <Td>Sıkılaştırılmış</Td>
            </Tr>
            <Tr>
              <Td>Kar Amacı Gütmeyen Kuruluşlar</Td>
              <Td>Yasaklı</Td>
              <Td>Yüksek Riskli Ülkeler/Bölgeler</Td>
              <Td>Sıkılaştırılmış</Td>
              <Td>Sık Nakit İşlemler</Td>
              <Td>Sıkılaştırılmış</Td>
              <Td>Çağrı Merkezi</Td>
              <Td>Sıkılaştırılmış</Td>
            </Tr>
            <Tr>
              <Td>Uyum Birimi İzleme Listesi</Td>
              <Td>Sıkılaştırılmış</Td>
              <Td>Saygın Ülkeler/Bölgeler</Td>
              <Td>Standart</Td>
              <Td>Kripto Varlık Transferleri</Td>
              <Td>Sıkılaştırılmış</Td>
              <Td>Web Sitesi</Td>
              <Td>Sıkılaştırılmış</Td>
            </Tr>
            <Tr>
              <Td>Siyasi Nüfuz Sahibi Kişiler (PEP)</Td>
              <Td>Sıkılaştırılmış</Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td>Mobil Uygulama</Td>
              <Td>Sıkılaştırılmış</Td>
            </Tr>
            <Tr>
              <Td>Riskli Mesleklere Sahip Kişiler</Td>
              <Td>Sıkılaştırılmış</Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td>Aplikasyonlar</Td>
              <Td>Sıkılaştırılmış</Td>
            </Tr>
            <Tr>
              <Td>Çifte Vatandaşlığı Bulunan Kişiler</Td>
              <Td>Sıkılaştırılmış</Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td>TradingView</Td>
              <Td>Sıkılaştırılmış</Td>
            </Tr>
            <Tr>
              <Td>Standart Müşteriler</Td>
              <Td>Standart</Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
        <Text mt={4}>
          Yüksek riskli olarak değerlendirilen müşteriler için sıkılaştırılmış müşteri tanıma tedbirleri uygulanır.
        </Text>
        <Text mt={4}>
          Yukarıda bahsi geçen faaliyetlerin itibar riski ve yasal risk doğurmadan kontrol altında tutulabilmesi amacıyla teknolojik çözümler kullanılır. Teknolojik çözümlerle ilgili parametrelerin belirlenmesi, senaryoların hazırlanması ve tatbik edilmesi, düzenli aralıklarla elde edilen bulgulara göre bunların gözden geçirilmesi sorumluluğu Norbi Uyum Görevlisi'ne aittir.
        </Text>
      </PolicySection>

      <PolicySection title="6. RİSK İŞTAHI BİLDİRİSİNİN UYGULANIŞI">
        <Text>
          Tüm üst düzey yöneticiler işbu Bildiri'nin uygulanmasından ve Bildiri'ye uyumlu olunmasından sorumludur.
        </Text>
        <Text mt={4}>
          Ulusal mevzuatta, müşterinin tanınmasına ilişkin temel düzenleme olarak 5549 Sayılı Kanun'da yer alan yasal yükümlülükler ve Suç Gelirlerinin Aklanmasının ve Terörün Finansmanının Önlenmesine Dair Tedbirler Hakkında Yönetmelik dikkate alınır. Uluslararası düzenlemeler kapsamında ise esas olarak FATF (Financial Action Task Force - Mali Eylem Görev Gücü) Tavsiyeleri'nin 10. önerisinde yer alan "Customer Due Diligence" ve "BASEL Committe on Banking Supervision" dokümanındaki ilkeler, uluslararası platformda müşterini tanı süreçlerinde izlenecek yöntemleri içerir.
        </Text>
        <Text mt={4}>
          Bu kapsamda müşterinin tanınmasına ilişkin süreçler, ulusal ve uluslararası mevzuatta yer alan unsurlar dikkate alınarak ve Bildiri'de tariflenen risk odaklı yaklaşım çerçevesinde oluşturulmuştur.
        </Text>

        <Text mt={4}>6.1. Yayım</Text>
        <Text mt={4}>
          Norbi Risk İştahı Bildirisi Norbi web sitesinde yayımlanır.
        </Text>

        <Text mt={4}>6.2. Risk Ölçüm Değerlendirmesi</Text>
        <Text mt={4}>
          Norbi; kara para aklama, terörizmin finansmanı ve yaptırım risklerinin periyodik olarak ölçülmesinde organizasyon genelini kapsayacak nitelikte bir çalışma yürütür. Norbi risk ölçümleme metodolojisi uçtan uca bir risk gözlem süreci olan müşterinin risk açısından skorlanması üzerine kuruludur.
        </Text>

        <Text mt={4}>6.3. Raporlama ve İzleme</Text>
        <Text mt={4}>
          Norbi'nin uyum birimi ("Uyum Birimi") tarafından uyumsuz olarak değerlendirilen risk toleransları Yönetim Kurulu'na raporlanmaktadır. Böylelikle risk iştahının yönetsel kararlarda da etkinliği sağlanmaktadır.
        </Text>

        <Text mt={4}>6.4. Gözden Geçirme</Text>
        <Text mt={4}>
          Uyum Birimi, Bildiri'yi yıllık olarak ya da uyum evreninde önemli bir değişiklik olduğu anda gözden geçirerek revize eder.
        </Text>
      </PolicySection>

      <PolicySection title="7. YÜRÜRLÜK VE REVİZYON">
        <Text>
          İşbu Bildiri'nin içeriği gizli olarak değerlendirilmelidir. Dolayısıyla ifşa edilmemesi veya yetkisiz kişilere dağıtılmaması gerekir. Bildiri'nin imzalı ve basılı nüshaları güvenli bir şekilde saklanmalı ve Uyum Birimi'nin yazılı izni olmadan çoğaltılmamalı veya yetkisiz üçüncü şahıslara ifşa edilmemelidir.
        </Text>
        <Text mt={4}>
          Norbi çalışanları, belirlenen politikalara uymak ve başkalarının uymasına yardımcı olmak için işbu Bildiri'yi ve ilgili eklerini okuyup anlamakla yükümlüdür.
        </Text>
        <Text mt={4}>
          İşbu Bildiri, Uyum Birimi tarafından hazırlanır ve Yönetim Kurulu tarafından onaylanarak yürürlüğe girer. Gerekli güncellemelerin yapılması adına Uyum Birimi tarafından en az yılda bir kez gözden geçirilir ve düzenli uyumluluk denetimi Norbi'nin iç denetim fonksiyonu ("İç Denetim") tarafından üstlenilir. Uyumsuzluk tespit edilen durumlarda, İç Denetim söz konusu uyumsuzluk vakalarının gerekçelerini inceleyecek ve gereğince raporlama yapacaktır. Bu incelemenin sonuçlarına bağlı olarak işbu Bildiri'de güncelleme yapılması ihtiyacı doğabilir. Uyum Birimi'nden belirlenen politikalarla ilgili personele yönelik genel veya özel bilgi notları yayımlaması talep edilebilir.
        </Text>
        <Text mt={4}>
          İşbu Bildiri'de düzenlenen hususlar ile ilgili yaşanabilecek uyumsuzlukların üst yönetime bildirilmesi Uyum Birimi'nin sorumluluğundadır. Uyumsuzluk vakaları, uyumsuzluğu fark eden diğer birimlerin personeli tarafından da rapor edilebilir. Bu gibi uyumsuzluklar, ilgili personel tarafından bağlı bulunduğu yöneticisine bildirilecektir. Durumdan haberdar olan yönetici ise bunu derhal Uyum Birimi'ne raporlayacaktır. İşbu Bildiri'ye uymayan Norbi personeli, Yaptırım Politikası uyarınca disiplin cezasına tabi tutulacaktır.
        </Text>
        <Text mt={4}>
          Bu Bildiri Mali Suçları Araştırma Kurulu, Türkiye Cumhuriyet Merkez Bankası, Kişisel Verileri Koruma Kurumu ve Hazine ve Maliye Bakanlığı kanun ve düzenlemeleri göz önünde tutularak hazırlanmıştır. Bu Bildiri ile bahsedilen kanun ve düzenlemeler arasında bir uyuşmazlık olması durumunda, kanun ve düzenlemeler öncelikli olacaktır. Bu durumda uygunluğu sağlamak için Bildiri'de gerekli düzenlemeler yapılacaktır.
        </Text>
      </PolicySection>
    </Box>
  );
};

export default RiskStatement;

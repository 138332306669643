import React from "react";
import { Box, Heading, Text, List, ListItem } from "@chakra-ui/react";

const RusvetYolsuzlukMucadele = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        Rüşvet ve Yolsuzlukla Mücadele Politikası
      </Heading>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        1. AMAÇ VE KAPSAM
      </Heading>

      <Heading as="h3" size="md" mt={6} mb={2}>
        1.1. Amaç
      </Heading>

      <Text>
        İşbu Yolsuzluk ve Rüşvetle Mücadele Politikasının (“Politika”) amacı
        rüşvet ve yolsuzluk riskleri ile söz konusu risklerle mücadele
        ilkelerini tanımlamak ve Yenkido Bilişim Hizmetleri Ticaret Anonim
        Şirketi’nin (“Şirket(imiz) veya Norbi”) faaliyet gösterdiği ülkede
        geçerli rüşvet ve yolsuzluk karşıtı yasa ve düzenlemeler, etik ilkeler
        ve evrensel kurallar ile uyumu sağlamaktır. Bununla birlikte,
        Müşterilerimizi, işlemlerimizi, ürün ve hizmetlerimizi risk temelli bir
        yaklaşımla değerlendirerek olası risklerin azaltılmasına yönelik
        stratejilerimizi, ilgili kontrol ve önlemlerimizi, işleyiş kurallarımızı
        ve sorumluluklarımızı belirleyerek tüm çalışanlarımızı bu konularda
        bilinçlendirmektir.
      </Text>

      <Text mt={4}>
        İşbu Politika aynı zamanda kaynağı rüşvet ve yolsuzluk suçları olan suç
        gelirlerinin Platformumuza yerleştirilmesinin ve aklanmasının önlenmesi,
        Şirket çalışanları, Müşteri ve iş ortakları arasında rüşvet ve yolsuzluk
        suçu doğmasına zemin hazırlayabilecek ortamın mümkün olduğunca ortadan
        kaldırılmasının ve tespitinin sağlanması, rüşvet ve yolsuzluk suçu ile
        ilgili yükümlülük ve taahhütlerin gereğinin yerine getirilmesini
        hedeflemektedir.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        1.2. Kapsam
      </Heading>

      <Text>
        İşbu Politika Yönetim Kurulu üyeleri, Genel Müdür, Üst Düzey Yöneticiler
        ve tüm Şirket çalışanlarını; bağlı ortaklıklarımızı ve çalışanlarını,
        yasal mevzuat ile düzenlenmiş destek hizmetleri de dahil olmak üzere dış
        hizmet aldığımız firmaları ve çalışanlarını, tedarikçiler, danışmanlar,
        avukatlar, dış denetçiler de dahil olmak üzere Şirket adına görev yapan
        kişi ve kuruluşlar (“İş Ortakları”) ile Müşterilerimizi kapsamaktadır.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        2. DAYANAK
      </Heading>

      <List spacing={3} mt={4}>
        <ListItem>5237 Sayılı Kanun'un 252. maddesi</ListItem>
        <ListItem>Birleşmiş Milletler Yolsuzlukla Mücadele Sözleşmesi</ListItem>
        <ListItem>Birleşmiş Milletler Küresel İlkeler Sözleşmesi</ListItem>
        <ListItem>ABD Yurtdışı Yolsuzluk Uygulamaları Kanunu (FCPA)</ListItem>
        <ListItem>Birleşik Krallık Rüşveti Önleme Yasası</ListItem>
        <ListItem>Sapin II – Fransız Rüşvetle Mücadele Kanunu</ListItem>
        <ListItem>
          Avrupa Konseyi Yolsuzluğa Karşı Ceza Hukuku Sözleşmesi
        </ListItem>
        <ListItem>
          Avrupa Konseyi Yolsuzluğa Karşı Avrupa Devletler Grubu (GRECO)
          Tavsiyeleri
        </ListItem>
        <ListItem>OECD Çok Uluslu Şirketler Rehberi</ListItem>
      </List>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        3. TANIMLAR
      </Heading>

      <Text>
        <strong>Yolsuzluk:</strong> Herhangi bir kurumdaki mevcut kaynak ve
        yetkilerin kişisel amaçlar için doğrudan veya dolaylı olarak kazanç
        sağlama amacıyla kötüye kullanılmasını ifade eder.
      </Text>

      <Text mt={4}>
        <strong>Rüşvet:</strong> Bir kişinin bir işi yapması, yapmaması,
        hızlandırması, yavaşlatması gibi yollarla görevinin gereklerine aykırı
        davranması için üçüncü bir kişiyle yapmış olduğu anlaşma çerçevesinde
        yarar sağlamasını ifade eder.
      </Text>

      <Text mt={4}>
        Rüşvet ve yolsuzluk pek çok farklı şekilde gerçekleştirilebilir, bunlar
        arasında:
      </Text>

      <List spacing={3} mt={4}>
        <ListItem>• Nakit ödemeler</ListItem>
        <ListItem>
          • Siyasi partiler ve hayır kurumlarına yapılan bağışlar
        </ListItem>
        <ListItem>• Alınan komisyonlar</ListItem>
        <ListItem>
          • Eşitsiz ve iç düzenlemelere aykırı olarak kullandırılan sosyal
          haklar
        </ListItem>
        <ListItem>• Hediye</ListItem>
        <ListItem>• Ağırlama faaliyetleri</ListItem>
        <ListItem>• Diğer menfaatler</ListItem>
        <Text>sayılabilir.</Text>
      </List>

      <Text mt={4}>
        <strong>Risk:</strong> Bir olayın meydana gelme ve hedeflerin başarısını
        olumsuz yönde etkileme olasılığı olarak tanımlanır. Ayrıca, Şirketimizce
        sunulan hizmetlerden finansal suçlardan doğan gelirlerin aklanması
        amacıyla yararlanılması, yolsuzluk ve rüşvet kapsamında belirtilen
        ulusal ve uluslararası düzenlemelere uyumun sağlanamaması veya
        Şirketimizin maruz kalabileceği mali ya da itibari zarar ihtimali.
      </Text>

      <Text mt={4}>
        <strong>Uyum Birimi:</strong> Yönetim Kurulunun bilgisi dahilinde Genel
        Müdür tarafından ataması yapılan Uyum Görevlisine bağlı olarak çalışan
        ve uyum programının yürütülmesiyle görevli çalışanlardan oluşan birim.
      </Text>

      <Text mt={4}>
        <strong>Üçüncü Taraf:</strong> Hissedarlar, iştirakler, grup firmaları,
        mevcut ve potansiyel olmak üzere; İş Ortakları, Müşteriler,
        tedarikçiler, partnerler, destek hizmeti kapsamında olan firmalar bu
        Politika kapsamında üçüncü taraf olarak kabul edilir.
      </Text>

      <Text mt={4}>
        <strong>Komisyon:</strong> Bir işte aracılık yapan kimseye hizmeti
        karşılığında yapılan ödeme.
      </Text>

      <Text mt={4}>
        <strong>TCK veya Kanun:</strong> 5237 sayılı Türk Ceza Kanunu’nu ifade
        eder. Rüşvet suçları, Kanun'un ikinci kitabının "Millete ve Devlete
        Karşı Suçlar ve Son Hükümler"in yer aldığı dördüncü kısmın birinci
        bölümünde "Kamu İdaresinin Güvenilirliğine ve İşleyişine Karşı Suçlar"
        bölümünde 252–254. maddeleri arasında bulunur. Aynı zamanda TCK'nın 282.
        maddesinin 1. fıkrasına göre ceza alt sınırı altı ay veya daha fazla
        hapis cezasını gerektiren suçlar malvarlığı değerlerini aklamanın öncül
        suçu olarak kabul edilir. Bu nedenle, Kanun'un 252. maddesinin 1.
        fıkrası uyarınca 4 yıldan 12 yıla kadar hapis cezasını gerektiren kamu
        görevlisine rüşvet verilmesi suçu, malvarlığı değerlerini aklamanın
        öncül suçudur.
      </Text>

      <Text mt={4}>
        <strong>Birleşmiş Milletler Küresel İlkeler Sözleşmesi:</strong> Sürekli
        rekabet içindeki iş dünyasında ortak bir kalkınma kültürü oluşturmak
        üzere evrensel ilkeler öneren yenilikçi bir kurumsal sorumluluk
        yaklaşımıdır. İnsan hakları, çalışma standartları, çevre ve yolsuzlukla
        mücadele başlığı altında toplam on küresel ilkenin geliştirilmesi
        hedeflenir.
      </Text>

      <Text mt={4}>
        <strong>Birleşmiş Milletler Yolsuzlukla Mücadele Sözleşmesi:</strong>{" "}
        Hem kamu hem de özel sektörde; yolsuzlukla mücadele, hesap
        verilebilirliği artırma ve sürdürülebilir kalkınma ve ilerlemeyi
        hedefleyen bir ilkeler bütünüdür. Yolsuzluğa karşı mücadele edecek
        birimler ve şeffaflığın sağlanmasına yönelik model birimler önerir.
        Türkiye tarafından 10 Aralık 2003 tarihinde imzalanmış olup 18 Mayıs
        2006 tarih ve 5506 sayılı Kanunla onaylanır.
      </Text>

      <Text mt={4}>
        <strong>Birleşik Krallık Rüşvet Kanunu:</strong> Birleşik Krallık'ta
        kurulmuş olan şirketler ve ticari kuruluşlar nezdinde rüşvet suçunu
        düzenleyen, 2010 yılında İngiliz Kraliyet Ailesi tarafından onaylanan
        kanun.
      </Text>

      <Text mt={4}>
        <strong>Platform(umuz):</strong> Norbi’ye ait internet siteleri, mobil
        uygulamalar veya yazılım uygulamaları (mevcut ve gelecekte kullanıma
        sunulacak olanlar da dahil) veyahut Şirket’in sağladığı diğer çevrimiçi
        hizmetler.
      </Text>

      <Text mt={4}>
        <strong>Müşteri(ler):</strong> Platform’u ziyaret eden, Platform’a üye
        olan veya Platform’daki özellik ve hizmetleri kullanan kişiler.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        4. YOLSUZLUK VE RÜŞVET KAPSAMINDAKI RİSKLİ ALANLAR
      </Heading>

      <Text>
        İşbu Politika kapsamında, Politika’ya uyum sağlamak ve uyumlu bir
        şekilde akışların devam ettirilmesi için Şirket ve İş Ortakları temel
        odak alanları;
      </Text>

      <List spacing={3} mt={4}>
        <ListItem>• Hediye kabulü ve sunumu</ListItem>
        <ListItem>• Temsil ve ağırlama faaliyetleri</ListItem>
        <ListItem>• Kolaylaştırma Ödemeleri</ListItem>
        <ListItem>
          • Bağış ödemeleri (Siyasi katkılar ve Hayır Kurumu Katkıları)
        </ListItem>
        <ListItem>• Görevin kötüye kullanımı</ListItem>
        <ListItem>• Üçüncü taraflar ve iş ortakları ile ilişkiler</ListItem>
        <ListItem>• Yasal Otoriteler ile ilişkiler</ListItem>
        <ListItem>
          • Sponsorluk ilişkileri kapsamında gereken aksiyonları
        </ListItem>
        <ListItem>• İşe Alım ve İstihdam</ListItem>
        <Text>olan önlemler almalıdırlar.</Text>
      </List>

      <Text mt={4}>
        Amacı ne olursa olsun rüşvet alınması ya da verilmesi kesinlikle kabul
        edilemez. Hizmet alan veya hizmet veren Üçüncü Taraf ile birlikte İş
        Ortakları için de bu ilkeler aranmaktadır. Şirketimiz İş Ortakları ile
        yapacağı anlaşmalarda İş Ortağı’nın ve çalışanlarının Politika’da
        belirtilen düzenleme ve esaslara uyum sağlamasını ve bu esaslara uygun
        davranmasını beklemektedir. Şirketimiz çalışanları haricindeki tüm
        menfaat sahipleri olan Üçüncü Taraflar ile faaliyetler; adil, şeffaf,
        dürüst, yasal ve etik kurallara uygun bir şekilde sürdürülmelidir. Gerek
        Şirket içi gerek Şirket dışı olan Üçüncü Taraflar, yasal otoriteler, İş
        Ortakları ve iş ilişkisinde olduğumuz diğer taraflara karşı doğrudan
        veya dolaylı olarak usulsüz bir şekilde ödeme yapma veya alma ve görevi
        kötüye kullanma kesinlikle yasaklanmıştır.
      </Text>

      <Text mt={4}>
        Rüşvet ve yolsuzluk eylemlerinin gerçekleşebileceği başlıca temel odak
        alanları aşağıda tanımlanmıştır.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        4.1. Hediye Kabulü ve Sunumu
      </Heading>

      <Text>
        Hediye, mevcut iş ilişkisi ile potansiyel iş beklentisi içerisindeki
        kişiler arasında maddi veya manevi bir karşılık gerektirmeyen ticari
        nezaket veya teşekkür amacıyla verilen ürün ve hizmetlerdir. Şirketimiz
        çalışanları sorumluluk ve karar alma süreçlerini etkileyebileceğini
        düşündüğü hediyeleri kabul etmemelidir. Kabul etmeme iradesinin karşı
        tarafça kesinlikle kabul görmediği, bu nedenle iş ilişkisinin zedelenme
        riski oluştuğu durumlarda, bir defaya mahsus olmak kaydıyla 1.250 TL'yi
        geçmeyecek değerde hediyeleri kabul edebilirler. Bir takvim yılında ise
        5.000 TL'yi geçmeyecek şekilde hediyeleri kabul edebilirler.
      </Text>

      <Text mt={4}>
        Şirketimiz çalışanlarının 1.250 TL’yi geçmeyecek değerde kabul ettiği
        her hediye için "Hediye Kabul Beyan Formu" nu doldurarak Norbi Uyum
        Birimi'ne teslim etmesi gerekmektedir. Hediye tutarının 1.250 TL'yi
        geçtiği durumda ise çalışan "Hediye İade Mektubu" formunu doldurarak
        hediyeyi kabul edemeyeceğine dair beyanını hediyeyi veren gerçek/tüzel
        kişi ile birlikte Norbi Uyum Birimi'ne teslim eder. Hediyeyi kabul
        ettiği halde "Hediye Kabul Beyan Formu" doldurmayan veya "Hediye Kabul
        Beyan Formu"nda beyan edilen hediye tutarının 1.250 TL'yi geçtiği Norbi
        Uyum Birimince tespit edilen çalışanlar hakkında İç Kontrol Birimi
        tarafından Hediye ve Ağırlama Politikasına aykırılık nedeniyle inceleme
        başlatılır.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        4.2. Temsil ve Ağırlama Faaliyetleri
      </Heading>

      <Text>
        Temsil ve Ağırlama faaliyetleri, iş ilişkisi veya potansiyel iş ilişkisi
        içerisindeki kişiler arasında temsil ve ağırlama amacıyla
        gerçekleştirilen davet, eğlence, yemek, kurumsal iletişim
        organizasyonları ile eğitim faaliyetleridir.
      </Text>

      <Text mt={4}>
        Şirketimiz adına çalışan ve Şirketimizi temsil eden çalışanlar
        tarafından gerçekleştirilmek istenen ağırlama faaliyetleri alenen, iyi
        niyetli ve koşulsuz olmalıdır. Temsil ve ağırlama amacıyla Şirketimiz
        çalışanlarının eğlence organizasyonu yapması veya yemek vermesi
        durumunda Şirketimiz tarafından belirlenen temsil ve ağırlama
        yetkilerinin kullanılmasına ilişkin esaslar uygulanmalıdır. Ayrıca,
        temsil ve ağırlamalar herhangi bir çıkar çatışması veya bu yönde bir
        algıya sebep olmamalıdır.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        4.3. Kolaylaştırma Ödemeleri
      </Heading>

      <Text>
        Kolaylaştırma ödemeleri, kolaylaştırıcı ödemeyi yapan tarafın yasal
        olarak hak kazanmış olduğu bir rutin veya gerekli işlemin yapılmasını
        güvence altına almak veya hızlandırmak için alt kademe bir görevliye
        yapılan resmi olmayan, uygunsuz, küçük tutarda ödemelerdir. Şirketimiz
        bünyesinde çalışanların veya Şirket adına faaliyet gösteren bireylerin
        herhangi bir kamu kurumundan veya kamu kurumuna bağlı bireylerden rutin
        işlemlerin ve süreçlerin (izin veya ruhsat almak, belge temin etmek,
        ihale işlemleri vb.) güvence altına alınması veya hızlandırması adına
        kolaylaştırılmış ödeme almasını veya yapmasını yasaklar. Yapılan
        işlemlerde bu yönde herhangi bir şüphe duyulması halinde, Şirket destek
        hattımıza (destek@norbiapp.com) (“Etik Hattı”) bildirilmesi gerekmektedir.
        Norbi İç Kontrol Birimi bu çerçevede raporlanan Etik Hattı
        bildirimlerini 5549 Sayılı Suç Gelirlerinin Aklanmasının Önlenmesi
        Hakkında Kanun kapsamında Norbi Uyum Birimi ile paylaşmalıdır.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        4.4. Bağış Ödemeleri (Siyasi Katkılar Hayır Kurumu Katkıları)
      </Heading>

      <Text>
        Şirketimiz bünyesinde çalışanların ve şirket adına görev yapan kişi ve
        kuruluşların Şirket adına herhangi bir siyasi partiye, siyasi vakaya,
        siyasi katkıda bulunmasını veya herhangi bağımsız siyasi adayı
        desteklemesini yasaklar. Yapılan katkılar, Şirketimizden bağımsız
        olması, ticari avantaj sağlamaması ve bireysel olması halinde bu
        kuraldan istisna tutulmaktadır. İşbu Politika’da bahse konu siyasi
        katkılar, siyasi amaç adına yapılan tüm ödemelerdir (bağışlar,
        hediyeler, servis tedariki, krediler, siyasi parti reklam ve
        pazarlaması, bağış toplama etkinliği biletleri alınması ve araştırma
        birimlerine katkılar vb).
      </Text>

      <Text mt={4}>
        Şirketimiz bünyesinde çalışanların ve Şirket adına görev yapan kişi ve
        kuruluşların hayır kurumlarını, Şirketimizden bağımsız olarak kendi
        imkânları ile desteklemesine kısıtlama getirmez. Ancak, hayır
        kurumlarına yapılacak bağışlarda Şirketimiz Etik İlkeler Politikası'nda
        yer alan prensiplere uyulması zorunludur.
      </Text>

      <Text mt={4}>
        Diğer yandan, Şirketimizin sosyal sorumluluk projeleri ve hayır
        kurumlarına bağış yapılması aşamasında; Uyum Birimi görüşü ardından
        Yönetim Kurulu onayı alınmalıdır. Norbi İç Kontrol Birimi bu çerçevede
        raporlanan Etik Hattı bildirimlerini 5549 Sayılı Suç Gelirlerinin
        Aklanmasının Önlenmesi Hakkında Kanun kapsamında Uyum Birimi ile
        paylaşmalıdır.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        4.5. Görevin Kötüye Kullanılması
      </Heading>

      <Text>
        Görevin kötüye kullanılması, sahip olunan yetkilerin karar alma
        sürecinde özel çıkar sağlamak suretiyle kullanılması anlamına
        gelmektedir. Şirketimiz çalışanları, sahip oldukları yetkileri ve
        görevleri yerine getirirken özel çıkarlar adı altında kendileri,
        yakınları, üçüncü taraflar lehine veya aleyhine karar alamazlar.
        Şirketimizin ve Müşterilerin zarar görmesine yol açacak bir şekilde
        hareket edemezler. Şirketimiz çalışanı, görevlerini yerine getirirken
        elde edilen bilgilerin gizliliğine bağlı kalmak; söz konusu bilgileri
        belirli bir yetki olmaksızın üçüncü kişilere veya taraflara açıklamamak
        ve kendisi veya üçüncü kişilerin veya tarafların çıkarlarına kullanmamak
        zorundadır. Bu ve buna benzer durumların Şirketimiz çalışanları
        tarafından tespit edilmesi halinde, Şirket destek hattımıza
        (destek@norbiapp.com) bildirilmesi gerekmektedir. Norbi İç Kontrol Birimi
        bu çerçevede raporlanan Etik Hattı bildirimlerini 5549 Sayılı Suç
        Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanun kapsamında Norbi Uyum
        Birimi ile paylaşmalıdır.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        4.6. Üçüncü Taraflar ve İş Ortakları
      </Heading>

      <Text>
        Hissedarlar, iştirakler, grup firmaları, mevcut ve potansiyel olmak
        üzere; iş ortakları, tedarikçiler, partnerler, destek hizmeti kapsamında
        olan gerçek ve tüzel kişiler bu Politika kapsamında üçüncü taraf olarak
        kabul edilir. Şirketimizin üçüncü taraflarla yaptığı faaliyetlerde,
        ilgili iş biriminin talep ettiği görüşlerle birlikte araştırmalar
        yapılır ve Etik Hattı’na yapılan bildirimler neticesinde takip edilir.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        4.7. Yasal Otoriteler ile İlişkiler
      </Heading>

      <Text>
        Şirketimizin yasal düzenlemeler nedeni ile iletişimde olduğu yabancı
        veya yerli yasal otoritelerde görevli memurlara doğrudan ya da dolaylı
        olarak verilecek hediye ve ikramlar, Şirketimiz Hediye Kabulü ve Sunumu
        ile Temsil ve Ağırlama Politikası'nda belirtilen sembolik ve maddi
        değeri yüksek olmayan hediyelerle kısıtlı tutulur. Hediye alımı ve
        sunulması ile beraber temsil ve ağırlama süreçlerine ilişkin usul ve
        esaslar Hediye Kabulü ve Sunumu ile Temsil ve Ağırlama Politikası'nda
        belirtildiği gibi uygulanmalıdır.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        4.8. Sponsorluk İlişkileri
      </Heading>

      <Text>
        Spor, sanat, kültür, eğitim, girişimcilik ve bilim alanlarında ya da bir
        aktivitenin veya organizasyonun desteklenmesi şeklinde olabilmektedir.
        Sponsorluklar, karşılık beklenmeden ve kamu yararına yapılmalıdır.
        Şirketimiz ve sponsorluk hizmeti için anlaşılan kuruluşlar arasında
        çıkar çatışmasına sebebiyet verecek herhangi bir endişe olmamalıdır.
        Yıllık olarak belirlenen veya yıl içinde şirket stratejileri
        doğrultusunda gerçekleşebilecek sponsorluklar, Uyum Birimi görüşü ile
        beraber Genel Müdür ve Yönetim Kurulu Başkanı'nın onayına sunulabilir.
      </Text>

      <Text mt={4}>
        Amacı ne olursa olsun rüşvet alınması ya da verilmesi kesinlikle kabul
        edilemez. Hizmet alan veya hizmet veren üçüncü taraflar ile birlikte iş
        ortakları için de bu ilkeler aranmaktadır. Şirketimiz çalışanları
        haricindeki tüm menfaat sahipleri olan üçüncü taraflar ile faaliyetler;
        adil, şeffaf, dürüst, yasal ve etik kurallara uygun bir şekilde
        sürdürülmelidir. Gerek Şirket içi gerek Şirket dışı olan üçüncü
        taraflar, yasal otoriteler, iş ortakları ve iş ilişkisinde olduğumuz
        diğer taraflara karşı doğrudan veya dolaylı olarak usulsüz bir şekilde
        ödeme yapma veya alma ve görevi kötüye kullanma kesinlikle
        yasaklanmıştır.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        4.9. İşe Alım ve İstihdam
      </Heading>

      <Text>
        Şirket işe alım ve istihdam süreçlerinde, Politika’nın bir gereği olarak
        ilgili iş biriminin talep ettiği görüşlerle birlikte araştırmalar
        yapılır ve Etik Hattı’na yapılan bildirimler neticesinde takip edilir.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        5. YOLSUZLUK VE RÜŞVET İLE MÜCADELE FAALİYETLERİ KAPSAMINDA DİKKAT
        EDİLECEK HUSUSLAR
      </Heading>

      <Heading as="h3" size="md" mt={6} mb={2}>
        5.1. Doğru Kayıt Tutulması
      </Heading>

      <Text>
        Şirketimiz herhangi bir tarafa ödeme yaparken işlemin mahiyetini
        doğrulamak adına tüm mali kayıtları tutmalı ve gerekli kontrol noktaları
        tesis etmelidir. İş birimleri, yolsuzluk ve rüşvet kapsamında riskli
        alanlar olarak kabul edilen;
      </Text>

      <List spacing={3} mt={4}>
        <ListItem>
          • Hediye alımı/verilmesi, ağırlama ve temsil organizasyonlarında; tüm
          ilgili fiş, fatura, makbuz vb. ticari evrakları
        </ListItem>
        <ListItem>
          •Şirket adına yapılacak olan hayır kurumu kapsamındaki katkılar için
          Yönetim Kurulu Onayı'na ilişkin dokümanı, onay belgesini ve bağış
          ödemeleri formunu
        </ListItem>
        <ListItem>
          •Üçüncü taraflar ve iş ortaklıkları kapsamında; firma bilgileri,
          teklif değerlendirme formu, sözleşme, fatura örneği vb. gibi tevsik
          edici belgeleri
        </ListItem>
        <ListItem>
          • Yasal Otoriteler ile ilişkiler kapsamındaki giderler için; tüm
          ilgili fatura, fiş, makbuz vb. ticari evrakları
        </ListItem>
        <ListItem>
          • Sponsorluk kapsamındaki giderler için sözleşme, fatura, fiş, makbuz,
          dekont örneği gibi ticari evrakları
        </ListItem>
        <Text>
          doğru bir şekilde kayıt altına alma, odak alanlarının gerçekleşmesi
          halinde Uyum Birimi'ne raporlama ve yasal süreler kapsamında
          muhafazadan sorumludur.{" "}
        </Text>
      </List>

      <Text mt={4}>
        Odak alanları kapsamında ortaya çıkan tevsik edici tüm belgeler uygun
        olarak sunulmalı, kayıtlar eksiksiz, doğru ve güvenilir bir şekilde
        kaydedilmeli, denetim ve resmi kurumlar tarafından yapılan incelemelerde
        ilgili kayıtlar hazır bulundurulmalıdır. Muhasebe veya herhangi bir özel
        işlem ile ilgili ticari kayıtların üzerinde oynanmamalı,
        değiştirilmemeli ve gerçeklerden çarpıtılmamalıdır.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        5.2. Raporlama ve Veri Koruma
      </Heading>

      <Text>
        Şirket adına hareket eden bir çalışan, Şirket içinde veya Şirket ile
        ilişki içerisinde olan bir tarafta yolsuzluk şüphesi duyduğunda,
        kendisine rüşvet teklif edildiğinde, rüşvet vermesi istendiğinde veya
        herhangi bir hukuka aykırı faaliyetin içinde kaldığında Destek Hattı'na
        (destek@norbiapp.com) raporlamalıdır.
      </Text>

      <Text mt={4}>
        Yapılan bildirim veya raporlama tamamen gizli tutulmalıdır. Bildirim
        veya raporlamayı yapan herhangi bir çalışanın disiplin cezası, işten
        çıkarılma, mobbing vb. olumsuz muameleye maruz kalması önlenmelidir.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        5.3. Politika İhlali
      </Heading>

      <Text>
        İşbu Politika’nın ihlali halinde Uyum Birimi tarafından inceleme
        yapılarak işbu Politika ihlali doğrulanırsa, Norbi İç Kontrol Birimi'ne
        bilgilendirme yapılır. Norbi İç Kontrol Birimi de 5549 Sayılı Suç
        Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanun kapsamında raporlanan
        Etik Hattı bildirimlerini Uyum Birimi ile paylaşmalıdır. Etik Hattı'na
        yapılan bildirimlerin gizli olması gerekmektedir. Bildirim yapan kişiler
        her şartta ve durumda gizli tutulur, hiçbir şekilde ifşa edilmez. Dış
        hizmet alınan ve iş ortaklıkları kapsamındaki kuruluşlar hakkında da
        inceleme yapılarak işbu Politika ihlali doğrulanırsa, Norbi İç Kontrol
        Birimi'ne bilgilendirme yapılır ve incelemeler neticesinde belirtilen
        kuruluşlar ile iş ilişkisi sonlandırılır.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        5.4. Eğitim Faaliyetleri
      </Heading>

      <Text>
        Eğitimler, çalışanların farkındalığının artırılması için önemli bir
        araçtır. Uyum Birimi, Politika’nın etkin bir şekilde izlenmesi ve
        Politika’nın uygunluğu ve değerlendirilmesinden sorumludur. Eğitim
        çalışmalarının planlanması ve koordinasyonu, Uyum Birimi'nin gözetiminde
        İnsan kaynakları Birimi tarafından planlanarak gerçekleştirilir. Eğitim;
        Uyum Birimi'nin, İnsan Kaynakları Birimi ile yapacağı ortak
        değerlendirmeler ve İç Kontrol Birimi'nin de görüşlerini alarak
        oluşturulur. Verilecek eğitim, Şirketimiz tüm çalışanlarını kapsar.
      </Text>

      <Text mt={4}>
        Şirket adına hareket eden tüm çalışanlar ve bireyler, Politika'nın
        başarılı şekilde uygulanmasından sorumludur.
      </Text>

      <Heading as="h3" size="md" mt={6} mb={2}>
        5.5. İzleme ve Kontrol Faaliyetleri
      </Heading>

      <Text>
        İzleme ve kontrol faaliyetleri, yolsuzluk ve rüşveti önleme ve bu
        kapsamdaki karşılaşabilecek risklerin kontrol altında tutulabilmesi için
        yapılacak çalışmaları içermektedir. Bu çalışmalar, ulusal ve
        uluslararası düzeydeki yolsuzluk ve rüşvet ile mücadele ilkeleri ve
        uluslararası alandaki en iyi uygulama örnekleri çerçevesinde
        oluşturulmaktadır.
      </Text>

      <Text mt={4}>
        Riskli olarak tanımlanan tüm unsurlar sürekli olacak şekilde; iş
        birimleri, Uyum Birimi ve İç Kontrol Birimi tarafından gözlem altında
        tutulmalıdır.
      </Text>

      <Text mt={4}>
        Şirketimizde yolsuzluk ve rüşvet ile mücadele kapsamında her zaman için
        izlenecek ve kontrol edilecek işlemler şunlardır;
      </Text>

      <List spacing={3} mt={4}>
        <ListItem>
          • Hediye kabul edilmesi veya sunulması ile Temsil ve Ağırlama
          faaliyetleri ile ilgili belirtilen işlemler kontrol altında tutulur.
        </ListItem>
        <ListItem>
          • Bağış ödemeleri (Siyasi Katkılar ve Hayır Kurumu Katkıları) ile
          ilgili işbu Politika ve Etik İlkeler Politikası’nda belirtilen
          esaslara göre hareket edilir ve kontrolleri yapılır.
        </ListItem>
        <ListItem>
          • Üçüncü taraflar ve iş ortaklıkları kapsamındaki faaliyetlerle ilgili
          olarak işbu Politika ve Etik İlkeler Politikası’nda belirtilen
          esaslara göre hareket edilir ve kontrolleri yapılır.
        </ListItem>
        <ListItem>
          • Sponsorluk faaliyetlerinde işbu Politika ve Etik İlkeler
          Politikası’nda belirtilen esaslara göre hareket edilir ve kontrolleri
          yapılır.
        </ListItem>
        <ListItem>
          • Eğitim faaliyetleri ile ilgili Şirketimiz tüm çalışanları ve işbu
          Politika kapsamında tüm ilgili tarafların bilinçlendirilmesi için
          eğitim faaliyet düzenlenir.
        </ListItem>
        <ListItem>
          • İstihdam ve personel değişiklikleri faaliyetleri ile ilgili
          Şirketimiz Etik İlkeler Politikası esaslarına göre hareket edilir.
        </ListItem>
        <ListItem>
          • İşbu Politika'da belirtilen riskli hususların ihbar bildirimleri
          Şirket destek hattımıza (destek@norbiapp.com) yapılır.
        </ListItem>
      </List>
      <Text mt={4}>
        Yolsuzluk ve rüşvet ile mücadele kapsamında tespit edilen hususlar için
        Şirketimiz Disiplin Yönetmeliği kapsamında gerekli tedbirler alınır.
      </Text>
      <Text mt={4}>
        Riskli olarak tanımlanan tüm unsurlarla ilgili yapılan bildirimler, Uyum
        Birimi tarafından belirli dönemlerle üst yönetime sunulur.
      </Text>
      <Heading as="h2" size="lg" mt={8} mb={4}>
        6. YÜRÜRLÜK VE REVİZYON
      </Heading>

      <Text>
        Norbi, işbu Politika içeriğini gerekli gördüğü zaman değiştirebilir.
        Değişiklikler, kural olarak Norbi'nin internet sitesine yüklenmek
        suretiyle yapılacak ve bu tarih itibariyle geçerlilik kazanacak olmakla
        birlikte Norbi, değişiklikleri uygun gördüğü diğer şekillerde de
        bildirilebilecektir. Güncel Politika, Site'de yayınlandığı tarihte
        yürürlüğe girecektir. Herhangi bir ihtilaf halinde, Norbi kayıtları tek
        başına geçerlidir ve kapsadığı kişiler için bağlayıcıdır.
      </Text>
    </Box>
  );
};

export default RusvetYolsuzlukMucadele;

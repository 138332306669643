import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  VStack,
  Icon,
  useBreakpointValue,
  Link,
  Flex,
} from "@chakra-ui/react";

import {
  TbCircleNumber1Filled,
  TbCircleNumber2Filled,
  TbCircleNumber3Filled,
} from "react-icons/tb";

const StyledText = ({
  children,
  color = "white",
  sizes = ["24px", "30px", "24px", "28px", "30px"],
  mb,
}) => (
  <Text fontSize={sizes} fontWeight="bold" color={color} mb={mb}>
    {children}
  </Text>
);

const FeatureCard = ({
  iconWhite,
  title,
  description,
  linkText,
  linkEmoji,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const titleColor = useBreakpointValue({
    base: "white",
    md: isHovered ? "white" : "white",
  });

  const baseBg = useBreakpointValue({
    base: `
      linear-gradient(135deg, #333 0%, #333 20%, transparent 51%),
      linear-gradient(315deg, #333 0%, #333 20%, transparent 51%),
      radial-gradient(circle at top right, rgba(146, 86, 231, 0.9), transparent 70%),
      radial-gradient(circle at bottom left, rgba(146, 86, 231, 0.9), transparent 70%)
    `,
    md: "#222",
  });

  return (
    <Box
      position="relative"
      borderRadius="16px"
      overflow="hidden"
      bg={baseBg}
      p={0.5}
      onMouseEnter={() => !isMobile && setIsHovered(true)}
      onMouseLeave={() => !isMobile && setIsHovered(false)}
      _hover={
        isMobile
          ? {}
          : {
              "&::before": {
                transform: "scale(1.5)",
                opacity: 1,
              },
            }
      }
      sx={
        isMobile
          ? {}
          : {
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background:
                  "radial-gradient(circle at var(--mouse-x) var(--mouse-y), rgba(146, 86, 231, 0.9), transparent 60%)",
                transition: "transform 0.3s, opacity 0.3s",
                pointerEvents: "none",
                opacity: 0,
              },
            }
      }
      onMouseMove={
        isMobile
          ? undefined
          : (e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              e.currentTarget.style.setProperty(
                "--mouse-x",
                `${e.clientX - rect.left}px`
              );
              e.currentTarget.style.setProperty(
                "--mouse-y",
                `${e.clientY - rect.top}px`
              );
            }
      }
      display="flex"
      flexDirection="column"
      minHeight="230px"
    >
      <Box
        position="relative"
        borderRadius="15px"
        overflow="hidden"
        bg="#111"
        p={6}
        flex="1"
        display="flex"
        flexDirection="column"
      >
        <VStack align="start" spacing={3} flex="1">
          <Box position="relative">
            <Icon
              as={iconWhite}
              boxSize={{ base: "30px", sm: "30px", lg: "38px" }}
              color={"#9156e6"}
            />
          </Box>
          <Heading
            size="md"
            color={titleColor}
            sx={{ WebkitFontSmoothing: "antialiased" }}
          >
            {title}
          </Heading>
          <Text
            fontSize={{ base: "14px", sm: "16px" }}
            color="rgba(255, 255, 255, 0.5)"
            sx={{ WebkitFontSmoothing: "antialiased" }}
          >
            {description}
          </Text>
          <Box>
            <Flex align="center">
              <Text>{linkEmoji}</Text>
              <Link href="https://web.norbi.app" isExternal>
                {linkText}
              </Link>
            </Flex>
          </Box>
        </VStack>
      </Box>
    </Box>
  );
};

function HowToStart() {
  const features = [
    {
      iconWhite: TbCircleNumber1Filled,
      title: "Kolayca Üye Olun",
      description:
        "Sadece telefon numaran yada email ve kişisel bilgilerini kullanarak saniyeler içinde kayıt ol.",
      linkText: "Hesap Oluştur",
      linkEmoji: "📝",
    },
    {
      iconWhite: TbCircleNumber2Filled,
      title: "Hesabınıza TL Yükleyin",
      description:
        "Anlaşmalı bankalar yada BiLira ile 7/24 para çekme ve yatırma işlemi yapabilirsiniz..",
      linkText: "Para Yatır",
      linkEmoji: "💵",
    },
    {
      iconWhite: TbCircleNumber3Filled,
      title: "Alım-Satım Yapmaya Başlayın",
      description:
        "200'e yakın paritede Türk lirası ile işlem yapmanın keyfini çıkarın.",
      linkText: "Alım-Satım Yap",
      linkEmoji: "📈",
    },
  ];

  return (
    <Box
      bg="transparent"
      py={4}
      color="white"
      mt={8}
      px={{ base: "5%", lg: 32 }}
    >
      <VStack spacing={4} align="stretch" maxW="1200px" mx="auto">
        <Box textAlign="center">
          <StyledText color="#9256E7" fontWeight="bold" mb={4}>
            3 ADIMDA KOYALCA BAŞLAYIN
          </StyledText>
          <Text
            fontSize={{ base: "24px", sm: "50px", md: "40px", lg: "50px" }}
            fontWeight="bold"
            sx={{ WebkitFontSmoothing: "antialiased" }}
            color={"rgb(236, 236, 236)"}
          >
            Nasıl Başlanır
          </Text>
        </Box>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
          {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
}

export default HowToStart;

import React from "react";
import {
  Box,
  SimpleGrid,
  VStack,
  Image,
  Text,
  Link,
  Flex,
  Button,
} from "@chakra-ui/react";
import NorbiIconSVG from "../../assets/logo/norbi_icon.svg";
import NorbiIconPNG from "../../assets/logo/norbiIcon.png";
import NorbiWhiteSVG from "../../assets/logo/norbi_logo_black.svg";
import NorbiWhitePNG from "../../assets/logo/norbi_logo_black.png";
import NorbiBlackSVG from "../../assets/logo/norbi_logo_white.svg";
import NorbiBlackPNG from "../../assets/logo/norbi_logo_white.png";
import LogosZip from "../../assets/norbi-logo.zip";
import BrandGuidelinesZip from "../../assets/NorbiBrandGuidelines.zip";

const StyledText = ({
  children,
  color = "white",
  sizes = ["24px", "30px", "24px", "28px", "30px"],
  mb,
}) => (
  <Text fontSize={sizes} fontWeight="bold" color={color} mb={mb}>
    {children}
  </Text>
);

const BoxWithText = ({ bg, text, image, isIcon, pngDownload, svgDownload }) => {
  return (
    <VStack spacing={3} align="stretch">
      <Box
        bg={bg}
        borderRadius="20px"
        overflow="hidden"
        p={6}
        border="1px solid #333"
        borderColor="linear-gradient(to bottom, #333333 50%, rgba(255,255,255,0.3) 100%)"
        transition="transform 0.3s ease-in-out"
        _hover={{
          textDecoration: "none",
        }}
      >
        <VStack align="center" spacing={3}>
          <Box
            w="full"
            h={{ base: "120px", sm: "160px", md: "220px", lg: "200px" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src={image}
              w={isIcon ? { base: "20%", md: "16%", lg: "20%" } : "auto"}
              maxW={isIcon ? "70px" : "100%"}
              maxH="80%"
              h="full"
              objectFit="contain"
            />
          </Box>
        </VStack>
      </Box>
      <Flex w="full" alignItems="center" justifyContent="space-between">
        <Flex gap="1" textStyle="small">
          <Text textAlign="start" fontWeight="bold" color="#FFF">
            {text.split(" ")[0]}
          </Text>
          <Text opacity="0.6" color="#FFF">
            {text.split(" ").slice(1).join(" ")}
          </Text>
        </Flex>
        <Flex>
          <Link href={pngDownload} download>
            <Button
              size={{ base: "2xs", sm: "xs" }}
              p="1"
              m={1}
              variant="pvPurple"
              boxShadow="0 3px 4px 1px rgba(0, 0, 0, 0.4)"
              borderRadius="3px"
            >
              <Text fontSize={{ base: 12, lg: 14 }} fontWeight="bold">
                PNG
              </Text>
            </Button>
          </Link>
          <Link href={svgDownload} download>
            <Button
              size={{ base: "2xs", sm: "xs" }}
              p="1"
              m={1}
              variant="pvTransparent2"
              boxShadow="0 3px 4px 1px rgba(0, 0, 0, 0.4)"
              borderRadius="3px"
            >
              <Text fontSize={{ base: 12, lg: 14 }} fontWeight="bold">
                SVG
              </Text>
            </Button>
          </Link>
        </Flex>
      </Flex>
    </VStack>
  );
};

const NorbiLogo = () => {
  return (
    <Box bg="transparent" py={20} color="white" minH="100vh">
      <VStack spacing={8} align="stretch" maxW="1200px" mx="auto">
        <Box textAlign="center" mb={6}>
          <StyledText color="#9256E7" fontWeight="bold" mb={6}>
            Norbi Logo
          </StyledText>
          <Text
            fontSize={{
              base: "24px",
              sm: "50px",
              md: "40px",
              lg: "50px",
            }}
            fontWeight="bold"
          >
            Logo Varyasyonları
          </Text>
        </Box>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
          <BoxWithText
            bg="#010101"
            text="Ana Koyu Arka Plan"
            image={NorbiBlackSVG}
            pngDownload={NorbiBlackPNG}
            svgDownload={NorbiBlackSVG}
          />
          <BoxWithText
            bg="#FFF"
            text="Ana Açık Arka Plan"
            image={NorbiWhiteSVG}
            pngDownload={NorbiWhitePNG}
            svgDownload={NorbiWhiteSVG}
          />
          <BoxWithText
            bg="#010101"
            text="Logo İkon"
            image={NorbiIconSVG}
            pngDownload={NorbiIconPNG}
            svgDownload={NorbiIconSVG}
            isIcon={true}
          />
        </SimpleGrid>
        <Flex justify="center" gap={4} mt={8}>
          <Link href={LogosZip} download>
            <Button variant="pvTransparent">
              <Text>Logoları İndir</Text>
            </Button>
          </Link>
          <Link href={BrandGuidelinesZip} download>
            <Button variant="pvTransparent">
              <Text>Marka Kılavuzunu Keşfet</Text>
            </Button>
          </Link>
        </Flex>
      </VStack>
    </Box>
  );
};

export default NorbiLogo;

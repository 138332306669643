import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  keyframes,
  Image,
  Text,
  useBreakpointValue,
  Center,
  VStack,
  Button,
  Spinner,
} from "@chakra-ui/react";

import { Trade } from "polyverse-sdk-dev/dist/api/trade";

import { formatPrice } from "../Utils";

const CardSlider = ({ arrayCount }) => {
  const navigate = useNavigate();

  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);

  const StyledText = ({
    children,
    color = "white",
    sizes = ["24px", "30px", "24px", "28px", "30px"],
    mb,
  }) => (
    <Text fontSize={sizes} fontWeight="bold" color={color} mb={mb}>
      {children}
    </Text>
  );

  const animationDuration =
    useBreakpointValue({
      base: "500s",
      sm: "1100s",
      md: "1000s",
    }) || "1000s"; // Default value

  const cardWidth =
    useBreakpointValue({
      base: "220px",
      sm: "240px",
      md: "260px",
    }) || "260px"; // Default value

  const cardHeight =
    useBreakpointValue({
      base: "88px",
      sm: "92px",
      md: "96px",
    }) || "96px"; // Default value

  const slideAnimation = keyframes`
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); }
  `;

  const loadInitialData = async () => {
    try {
      const symbols = await Trade.spot.symbols("TRY");
      processSymbols(symbols);
      setLoading(false);
      connectWebSocket(symbols);
    } catch (error) {
      console.error("Error fetching symbols", error);
      setLoading(false);
    }
  };

  const processSymbols = (symbols) => {
    //en çok işlem görenlere göre sırala
    const filteredSymbols = symbols
      .slice()
      .sort((a, b) => b.price.count - a.price.count);

    const updatedCards = filteredSymbols.map((symbol) => {
      return {
        symbol: symbol.symbol,
        ticker: symbol.ticker,
        image:
          symbol.logoUrl ||
          `https://via.placeholder.com/150?text=${symbol.symbol}`,
        baseAsset: symbol.baseAsset,
        quoteAsset: symbol.quoteAsset,
        price: formatPrice(symbol.price.lastPrice, "price", false, true),
        change: formatPrice(
          symbol.price.priceChangePercent,
          "percent",
          false,
          false
        ),
        volume: formatPrice(symbol.price.quoteVolume, "volume", true, true),
      };
    });

    setCards(updatedCards);
  };

  const connectWebSocket = (symbols) => {
    const ws = new WebSocket(
      "wss://stream.binance.com:9443/ws/!miniTicker@arr@3000ms"
    );

    ws.onmessage = (event) => {
      const messages = JSON.parse(event.data);
      messages.forEach((message) => {
        updateCardPrice(message);
      });
    };

    ws.onclose = () => {
      console.log("WebSocket closed. Reconnecting...");
      setTimeout(() => connectWebSocket(symbols), 5000);
    };
  };

  const updateCardPrice = (message) => {
    setCards((prevCards) => {
      const updatedCards = prevCards.map((card) => {
        if (card.ticker === message.s) {
          return {
            ...card,
            price: formatPrice(message.c, "price", false, true),
            change: formatPrice(
              ((message.c - message.o) / message.o) * 100,
              "percent",
              false,
              false
            ),
            volume: formatPrice(message.q, "volume", true, true),
            isUpdated: true, // Yeni anahtar, animasyon için
          };
        }
        return card;
      });

      // Animasyonu temizlemek için kısa bir süre sonra isUpdated anahtarını false yapın
      setTimeout(() => {
        setCards((prevCards) =>
          prevCards.map((card) => ({
            ...card,
            isUpdated: false,
          }))
        );
      }, 1000); // 1 saniye sonra animasyonu temizle

      return updatedCards;
    });
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  return (
    <Box
      py={0}
      my={{ base: 12, md: 12, lg: 20 }}
      position="relative"
      overflow="hidden"
      w="full"
      bg="transparent"
    >
      <VStack spacing={8} align="stretch" maxW="1200px" mx="auto">
        <Box textAlign="center">
          <StyledText color="#9256E7" fontWeight="bold" mb={4}>
            KOLAY AL, KOLAY SAT
          </StyledText>
          <Text
            fontSize={{ base: "24px", sm: "50px", md: "40px", lg: "50px" }}
            fontWeight="bold"
            sx={{ WebkitFontSmoothing: "antialiased" }}
            color={"rgb(236, 236, 236)"}
          >
            Norbi ile Kripto Varlıklara Erişim Kolaylığı
          </Text>
          <Text
            color="#888"
            fontSize={{ base: "16px", sm: "25px", md: "20px", lg: "25px" }}
          >
            Bitcoin ve diğer kripto varlıkları güvenli, hızlı ve kolay bir
            şekilde Türk Lirasıyla alın, satın ve yatırım yapın. {arrayCount}
            'den fazla paritede işlem yapma fırsatı sizleri bekliyor!
          </Text>
        </Box>
        {loading ? (
          <Center>
            <Spinner color="#9156e7" />
          </Center>
        ) : (
          <Box
            display="flex"
            w={`${(cards.length * 100) / 2}%`}
            animation={`${slideAnimation} ${animationDuration} linear infinite`}
            transform="translate3d(0, 0, 0)"
          >
            {cards.map((card) => (
              <Flex
                key={card.symbol}
                bg="#1A1A1A"
                boxShadow="md"
                borderRadius="12px"
                p={4}
                justifyContent="space-between"
                width={cardWidth}
                height={cardHeight}
                mx={2}
                flexShrink={0}
                direction="column"
                className={card.isUpdated ? "flash" : ""}
              >
                <Flex direction="row" justifyContent="space-between">
                  <Flex alignItems="center" gap="2">
                    <Image
                      src={card.image}
                      borderRadius="full"
                      boxSize="24px"
                    />
                    <Text fontWeight="bold" fontSize="md">
                      {card.baseAsset}/{card.quoteAsset}
                    </Text>
                  </Flex>
                  <Text
                    fontSize="md"
                    color={card.change.includes("-") ? "red.500" : "green.500"}
                  >
                    {card.change ?? "0.00%"}
                  </Text>
                </Flex>

                <Flex
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                >
                  <Text fontSize="md" color="#9256e7">
                    ₺{card.price}
                  </Text>
                  <Text fontSize="md" color="gray.500">
                    {card.volume}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Box>
        )}

        <Center>
          <Box>
            <Button
              variant="pvGradient"
              width={{ base: "160px", md: "360px", lg: "360px" }}
              p={4}
              onClick={() => navigate("/prices")}
            >
              Piyasaları İncele
            </Button>
          </Box>
        </Center>
      </VStack>
    </Box>
  );
};

export default CardSlider;

// CSS eklemeleri
const styles = `
.flash {
  animation: flash-animation 1s ease-in-out;
}

@keyframes flash-animation {
  0% { background-color: #1A1A1A; }
  50% { background-color: #444; }
  100% { background-color: #1A1A1A; }
}
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

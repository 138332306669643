import React from "react";

import {
  Box,
  Heading,
  List,
  ListItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

const Commissions = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        Alış - Satış Komisyonları
      </Heading>

      <List mt={4} spacing={4}>
        <ListItem>
          Alış satış komisyon oranlarına %20 KDV dahildir. Her kullanıcının tek
          bir komisyon kademesi vardır.
        </ListItem>
        <ListItem>
          30 Günlük Hacim Hesaplaması: İşlem hacimleri hesaplanırken, Türk
          Lirası ve Tether işlem çiftlerinde yapılan işlemlerin son 30 günlük
          toplam işlem hacimleri Türk Lirası bazında hesaplanır.
        </ListItem>
      </List>

      <Table variant="simple" mt={4}>
        <Thead>
          <Tr>
            <Th>Seviyeniz</Th>
            <Th>30 Günlük Alım Satım Hacmi (TRY)</Th>
            <Th>Piyasa Yapıcı/Piyasa Alıcı</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Bronz</Td>
            <Td>0 - 1,000,000</Td>
            <Td>0.2% / 0.3%</Td>
          </Tr>
          <Tr>
            <Td>Gümüş</Td>
            <Td>1,000,000 - 5,000,000</Td>
            <Td>0.18% / 0.3%</Td>
          </Tr>
          <Tr>
            <Td>Altın</Td>
            <Td>5,000,000 - 10,000,000</Td>
            <Td>0.16% / 0.3%</Td>
          </Tr>
          <Tr>
            <Td>Platinum</Td>
            <Td>10,000,000 - 50,000,000</Td>
            <Td>0.08% / 0.3%</Td>
          </Tr>
          <Tr>
            <Td>Elmas</Td>
            <Td>50,000,000+</Td>
            <Td>0.04% / 0.2%</Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

export default Commissions;

import { Box, Heading, Text, Link, List, ListItem } from "@chakra-ui/react";

const UserAgreement = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        YENKİDO BİLİŞİM HİZMETLERİ TİCARET ANONİM ŞİRKETİ
      </Heading>

      <Heading as="h2" mb={8} textAlign="center">
        KULLANICI SÖZLEŞMESİ
      </Heading>
      <Text mb={4}>Son Güncelleme: 18.09.2024</Text>

      <Heading as="h2" size="md" mt={6}>
        1. GİRİŞ
      </Heading>
      <Text mt={4}>
        1.1 Halaskargazi Cad. No: 38-66E İç Kapı No: 215, Nişantaşı Lotus, 34371
        Şişli/İstanbul adresinde mukim Yenkido Bilişim Hizmetleri Ticaret Anonim
        Şirketi ("Norbi", "Şirketimiz" veya "biz") olarak internet sitelerimiz (
        <Link href="https://norbi.app" isExternal>
          https://norbi.app
        </Link>
        ,{" "}
        <Link href="https://web.norbi.app" isExternal>
          https://web.norbi.app
        </Link>
        ,{" "}
        <Link href="https://polyverse.life" isExternal>
          https://polyverse.life
        </Link>
        ) ve mobil uygulamamız (bundan sonra hep birlikte "Platform") üzerinden
        siz Kullanıcılarımıza kripto varlık hizmetlerini sunmaktayız.
      </Text>
      <Text mt={4}>
        1.2 Platformumuz’dan kripto varlık alım/satımlarınızı
        gerçekleştirebilir, Norbi Cüzdan hizmetimize (“Cüzdan Hizmeti”)
        erişebilir ve size sunduğumuz diğer özellik ve hizmetlerimizden
        yararlanabilirsiniz. Platformumuz üzerinden ulaşabileceğiniz Cüzdan
        Hizmeti, çeşitli Ethereum uyumlu blok zinciri ağlarında talimatlarınız
        doğrultusunda işlem yapma olanağı sağlayan bir akıllı cüzdandır
        (“Cüzdan”). Bu Cüzdan’la; uyumlu Kripto Varlıklar’ın bakiyelerini
        görüntüleyebilir, Kripto Varlıklar’ı içeren transferleri, işlemleri
        başlatabilir veya potansiyel olarak diğer ürünlere ve oyunlara erişmek
        için bu Cüzdan’ı bir kimlik doğrulama hesabı olarak kullanabilirsiniz.
        Buna ek olarak Cüzdan, merkezi olmayan işlem platformları gibi
        uygulamalara erişmenize ve bu uygulamalar aracılığıyla Kripto Varlık
        işlemlerinde bulunmanıza olanak sağlayabilir.
      </Text>
      <Text mt={4}>
        1.3 İşbu Kullanıcı Sözleşmesi (“Sözleşme”), Platform’u ziyaret eden,
        Platform’a üye olan veya Platform’daki özellik ve hizmetleri kullanan
        kişiler (“siz” veya “Kullanıcı”) ile Norbi arasındaki hüküm ve koşulları
        düzenlemektedir ve Kullanıcılar’ın Platform’u kullanımına ilişkin
        tarafların hak ve sorumluluklarını belirlemeyi amaçlamaktadır.
      </Text>
      <Text mt={4}>
        1.4 İşbu Sözleşme, Norbi ile Kullanıcı arasında bağlayıcı bir sözleşme
        teşkil etmekte olup Norbi veya Kullanıcı tarafından burada belirtilen
        prosedürlere uygun olarak feshedilmediği sürece geçerli olacaktır.
      </Text>
      <Text mt={4}>
        1.5 Platform’da bulunan hiçbir materyal veya bilgi, Kripto Varlıklar
        veya diğer ürünlerle ilgili herhangi bir işlem için bir öneri, onay,
        teklif, davet veya talep olarak yorumlanmamalıdır. Platform; vergi,
        hukuk, sigorta veya yatırım tavsiyesi sağlamak için tasarlanmamıştır ve
        Platform’daki hiçbir şey, herhangi bir Kripto Varlık için Norbi
        tarafından bir satış teklifi, satın alma teklifi veya tavsiye olarak
        görülmemelidir. Platform’da yer alan herhangi bir işlemin, ürünün veya
        hizmetin finansal hedeflerinize ve kişisel ve finansal koşullarınıza
        göre uygunluğunu belirlemekten yalnızca siz sorumlusunuz. Herhangi bir
        Kripto Varlık satın almanın çok sayıda risk taşıdığını ve Risk Açıklama
        Bildirimi’nin ilgili risk faktörlerinin tam bir listesini veya
        açıklamasını kapsamayabileceğini kabul edersiniz. Piyasaların sürekli
        hareketli olabileceğini unutmayın; bu nedenle, Platform’da veya Platform
        aracılığıyla sağlanan bilgiler, içerikler, üçüncü taraf içerikleri veya
        diğer materyaller güncel veya tam olmayabilir ve daha güncel bilgilerle
        değiştirilebilir.
      </Text>
      <Text mt={4}>
        1.6 Norbi, hizmet sunulan bölge veya ülke bazında sunulan hizmet
        türlerini ve özelliklerini kendi takdirine göre belirleme yetkisine
        sahiptir. Platform’da yer alan bazı özellikler bulunduğunuz bölge veya
        ülkede geçerli olmayabilecektir. Norbi hizmetlerinin belirli bir yerde
        sunulup sunulmadığı veya sunulan hizmetlerin türü hakkında herhangi bir
        garanti vermemektedir.
      </Text>
      <Text mt={4}>
        1.7 Norbi, Kripto Varlıklar’da gerçekleştirdiğiniz herhangi bir işlem
        veya risk neticesinde doğacak olumsuz sonuçlardan sorumlu olmayacaktır.
        Norbi, Platform’da listelenen Kripto Varlıklar’ın riskini taahhüt
        etmemektedir. Alım-satım ve yatırımlarınızı kendi takdiriniz
        doğrultusunda yönlendirmeniz gerekmektedir.
      </Text>
      <Text mt={4}>
        1.8 Şüpheye mahal vermemek adına, tüm formlar, duyurular, bildirimler ve
        ekler, işbu Sözleşme’nin eki ve ayrılmaz bir parçasıdır. Norbi hizmet
        veya ürünlerine herhangi bir şekilde erişerek veya bunları kullanarak
        işbu Sözleşme ile bağlı olduğunuzu, onayladığını ve kabul ettiğinizi
        beyan etmiş sayılırsınız.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        2. TANIMLAR
      </Heading>
      <List mt={4} spacing={4}>
        <ListItem>
          2.1 "Platform", (
          <Link href="https://norbi.app" isExternal>
            https://norbi.app
          </Link>
          ,{" "}
          <Link href="https://web.norbi.app" isExternal>
            https://web.norbi.app
          </Link>
          ,{" "}
          <Link href="https://polyverse.life" isExternal>
            https://polyverse.life
          </Link>
          ) uzantılı internet sitelerimiz ile mobil uygulamamızı ifade eder.
        </ListItem>
        <ListItem>
          2.2 "Hizmetler" Kripto Varlık Cüzdan Hizmeti dahil Platform üzerinden
          sunulan tüm hizmetleri ifade eder.
        </ListItem>
        <ListItem>
          2.3 "Kullanıcı", Platformu ziyaret eden, Platform'a üye olan veya
          Platform'daki özellik ve hizmetleri kullanan kişileri ifade eder.
        </ListItem>
        <ListItem>
          2.4 “Kripto Varlık”, dağıtık defter teknolojisi veya benzer bir
          teknoloji kullanılarak sanal olarak oluşturulup saklanabilen, dijital
          ağlar üzerinden dağıtımı yapılan ve değer veya hak ifade edebilen
          gayri maddi varlıkları ifade eder.
        </ListItem>
        <ListItem>
          2.5. "Kişisel Veri", kimliği belirli veya belirlenebilir gerçek kişiye
          ilişkin her türlü bilgidir.
        </ListItem>

        <ListItem>
          2.6 "Varlık", dijital olarak oluşturulmuş ve saklanabilen, dağıtık
          defter teknolojisi veya benzer teknolojiler kullanılarak dijital ağlar
          üzerinden dağıtımı yapılabilen, değer veya hak ifade eden gayri maddi
          varlıkları kapsar. Bu tanım, kripto varlıklar, NFT'ler (Non-Fungible
          Token), tokenlar ve diğer tüm dijital varlıkları içerir.
        </ListItem>
      </List>

      <Heading as="h2" size="md" mt={6}>
        3. HESABINIZ VE ÜYELİK
      </Heading>
      <Text mt={4}>
        3.1 Platform üzerinden sunduğumuz hizmet ve özelliklerin tamamına
        erişebilmek veya bunları kullanabilmek için Platform üzerinde bir hesap
        oluşturmanız gerekmektedir (“Hesap”). Hesap oluşturma aşamasında
        Hesap’ınızın adınıza tanımlanabilmesi adına sizden adınız ve e-posta
        adresiniz dahil bazı bilgileri bizimle paylaşmanızı talep edebiliriz.
      </Text>
      <Text mt={4}>
        3.2 İşbu Sözleşme kapsamında Kullanıcı Hesap’ını açmaya yetkili ve ehil
        olduğunuzu, tabi olunan kanunlar kapsamında hak ve fiil ehliyetine sahip
        olduğunuzu kabul, beyan ve taahhüt edersiniz. Kullanıcı’nın bir tüzel
        kişi olması durumunda yetkili imza sahibi kayıt sürecini başlatmaktan ve
        kuruluş adına bu Sözleşme’ye onay vermekten sorumlu olacaktır.
      </Text>
      <Text mt={4}>
        3.3 Bize sağladığınız tüm bilgilerin doğru, güncel ve eksiksiz olduğunu
        ve gerektiğinde bu bilgileri güncelleyeceğinizi kabul edersiniz. Bize
        sağladığınız bilgilerin doğru ya da güncel olmaması veya e-postanızı
        doğrulayamadığınız durumda Norbi’nin Hesap’ınızı oluşturmayabileceğini
        veya mevcut Hesap’ınıza erişiminize son verebileceğini kabul edersiniz.
      </Text>
      <Text mt={4}>
        3.4 Hesap’ınızı üçüncü kişilere kullandıramaz, devredemez veya
        satamazsınız. Şifreniz ve Hesap’ınızı üçüncü kişilerle
        paylaşmayacağınızı ve Hesap’ınızı sizin dışınızda başka bir kişinin
        kullanmayacağını kabul ve taahhüt edersiniz. Hesap’ınızın güvenliğinden
        ve sizin Hesap’ınızdan gerçekleştirilen tüm işlemlerden tek başınıza
        sorumlu olduğunuzu ve Hesap’ınızdan gerçekleştirilen tüm işlemlerin size
        ait olduğunu kabul ve taahhüt edersiniz. Eğer Hesap’ınızın yetkisiz
        kişiler tarafından kullanıldığını veya ele geçirildiğini düşünüyorsanız
        Norbi’yi derhal bilgilendirmeniz gerekmektedir.
      </Text>
      <Text mt={4}>
        3.5 Norbi, size ait şifre, anahtar, dosya ve kodları kaybetmeniz
        nedeniyle yaşayabileceğiniz herhangi bir zarardan sorumlu olmayacaktır.
      </Text>
      <Text mt={4}>
        3.6 Hesap’ınızın oluşturulabilmesi veya Platform’da yer alan
        özelliklerin bazılarından yararlanabilmeniz için kimliğinizin tespit
        edilmesi adına sizden bilgi ve belgeler talep edebileceğiz. Kimliğinizin
        doğrulanamaması durumunda, ilgili bilgilerin eksikliği sebebiyle
        Hesap’ınızı oluşturamayacağımızı veya gerçekleştirmek için talepte
        bulunduğunuz işlemi tamamlayamayacağımızı kabul edersiniz. Norbi her
        zaman ek bilgi ve belge talebinde bulunabilecek olup, sunulan bilgilere
        ilişkin doğrulamanın sağlanamadığı durumlarda veya herhangi bir nedenle
        kendi takdirine bağlı olarak Hesap’ınızı oluşturmama veya oluşturulmuş
        Hesap’ınıza erişiminizi sona erdirme hakkını saklı tutmaktadır. Norbi,
        hukuka uygun hareket etmek amacıyla yetkili kurum ve kuruluşların talebi
        veya ilgili düzenlemeler nedeniyle sizinle ilgili bilgileri bu yetkili
        kurum ve kuruluşlarla paylaşabilecektir. (Kişisel verilerinizin
        korunması bakımından lütfen 7. Maddeyi inceleyiniz.)
      </Text>
      <Text mt={4}>
        3.7 Gerekli veya uygun gördüğümüz kimlik doğrulama teknolojilerini,
        hizmetlerini veya önlemlerini uygulama hakkımızı saklı tutarız. Bununla
        birlikte, Norbi bu tür kimlik doğrulama teknolojilerinin, hizmetlerinin
        veya önlemlerinin Platform’a veya Hesap’ınıza yetkisiz erişimi veya
        kullanımı önlemede veya bilgisayar korsanlığını veya kimlik hırsızlığını
        önlemede tamamen güvenli, yeterli veya başarılı olacağını garanti etmez.
      </Text>
      <Text mt={4}>
        3.8 Kullanıcı, yasal olarak reşit, mümeyyiz ve tam ehliyet sahibi
        olduğunu, her bir Kripto Varlık işlemleri ilgili risklerin farkında
        olduğunu ve bu işlemlerle ilgili kararların kendi sorumluluğunuzda
        olduğunu kabul ettiğini, verilen veya verilecek tüm bilgilerin doğru ve
        kesin olduğunu, yanıltıcı olmadığını, Hizmetler’i kullanırken kara para
        aklama, yolsuzlukla mücadele ve terörle mücadele ile ilgili düzenlemeler
        dahil, ancak bunlarla sınırlı olmamak üzere, sizin için geçerli olan tüm
        yasa ve düzenlemeleri ihlal etmeyeceğini, Platform’da işlem yapılan
        Kripto Varlıklar’a ait kripto cüzdanlara ve adreslere yaptığı
        aktarımlardan kendisinin mesul olduğunu, Platform’u kullanması sebebiyle
        doğacak tüm vergi mükellefiyetlerinden ve yasal düzenleme ile
        getirilmesi muhtemel diğer yükümlülüklerden sorumlu olduğunu, herhangi
        bir ticari ambargoya, ekonomik yaptırımlar listesine veya herhangi bir
        kurum tarafından bu amaçla özel olarak belirlenen bir listeye ya da
        reddedilen kişiler listelerine dahil edilmediğini beyan, kabul ve
        taahhüt eder.
      </Text>
      <Text mt={4}>
        3.9 Kullanıcı, 5549 sayılı “Suç Gelirlerinin Aklanmasının Önlenmesi
        Hakkında Kanun”un 15. maddesi uyarınca Norbi nezdinde açtığı ve açacağı
        her türlü hesapta kendi adına ve hesabına hareket ettiğini; başkası
        hesabına hareket etmediğini; gerçekleştirilen işlemlerde kullanılan
        kripto varlıklar dahil her türlü mal varlığının suçtan elde edilen gelir
        niteliğinde olmadığını; kendisi dışında başka gerçek veya tüzel ya da
        tüzel kişiliği olmayan teşekküller adına ya da hesabına hareket
        etmediğini; işlemlerin tamamının kendi varlığı ile kendi nam ve hesabına
        gerçekleştirildiğini; başkası hesabına hareket etmesi halinde kimin
        hesabına işlem yaptığını ve adına işlem gerçekleştirilen gerçek ya da
        tüzel kişiler ile tüzel kişilikler dışındaki teşekkülleri temsile
        yetkili kişi ya da kişilerin kimlik bilgilerini derhal yazılı olarak
        bildireceğini; bu yükümlülüğe uymaması durumunda Norbi’nin karşı karşıya
        kalacağı her türlü maddi kayıptan sorumlu olacağını; Norbi’nin söz
        konusu maddi kayıpları kendisinden talep etmesi halinde başkaca bir
        hukuki işleme gerek olmaksızın defaetle ödeyeceğini kabul, beyan ve
        taahhüt eder.
      </Text>
      <Text mt={4}>
        3.10 Kullanıcı, Kripto Varlıklar ile ilgili T.C. Hazine ve Maliye
        Bakanlığı, Bankacılık Denetleme ve Düzenleme Kurulu, Sermaye Piyasası
        Kurulu, Türkiye Bilimsel ve Teknolojik Araştırma Kurumu, Bilgi
        Teknolojileri ve İletişim Kurumu tarafından yapılan ve Sözleşme’nin
        akdedilme tarihinden sonra yapılacak tüm açıklamaları okumuş ve bilgisi
        dahilinde olduğunu kabul etmiş sayılmaktadır.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        4. PLATFORM
      </Heading>
      <Text mt={4}>
        4.1 Norbi, Platform’da Kullanıcılar’a sunduğu hizmetlerde değişiklik
        yapma, yeni hizmetler ekleme, mevcut hizmetleri Platform’dan kaldırma
        hakkını her zaman saklı tutmaktadır. Kullanıcılar Platform’da sunulan
        hizmetleri, hukuka ve işbu Sözleşme’ye uygun olarak kullanma hakkına
        sahiptir.
      </Text>
      <Text mt={4}>
        4.2 Platform’u ve Hizmetler’i kullanırken geçerli ve yürürlükte bulunan
        ilgili tüm hukuki düzenlemelere uyacağınızı, Platform aracılığıyla
        hukuka veya ahlaka aykırı bir işlem gerçekleştirmeyeceğinizi veya
        Platform’u, gerçekleştirdiğiniz hukuka aykırı işlemlerde araç olarak
        kullanmayacağınızı, aksi halde tüm sorumluluğun sizde olduğunu ve
        Platform’da gerçekleştirdiğiniz tüm işlem ve eylemlerden hukuki ve cezai
        olarak sorumlu olduğunuzu beyan, kabul ve taahhüt edersiniz. Norbi,
        uymanız gereken kanunları, kural veya yasakları size hatırlatmak veya
        bildirmekle yükümlü değildir. Kripto Varlık işlemleri ile ilgili doğacak
        tüm yasal sorumluluklar ve vergi mükellefiyetlerinden Kullanıcılar
        bizzat ve tek başına sorumludur.
      </Text>
      <Text mt={4}>
        4.3 Kullanıcı, Platform’u ve Hizmetler’i kullanırken aşağıda yer alan
        kurallara uyacağını, aksi halde ortaya çıkan tüm zararlardan sorumlu
        olduğunu beyan, kabul ve taahhüt eder.
      </Text>
      <List mt={4} spacing={4}>
        <ListItem>
          • Üçüncü bir kişi adına veya üçüncü bir kişinin kimlik bilgileri
          kullanılarak Hesap açılmamalıdır.
        </ListItem>
        <ListItem>
          • Platform’da gerçekleştirilen tüm işlemlere konu fon veya malvarlığı
          yasadışı bir kaynaktan elde edilmiş olmamalı veya yasadışı bir amaçla
          kullanılmamalıdır.
        </ListItem>
        <ListItem>
          • Kullanıcı, Norbi’nin talep etmesi halinde işlemin amacı ya da işleme
          konu fonun kaynağı hakkında açıklama yapmalıdır.
        </ListItem>
        <ListItem>
          • Kullanıcı, yasal veya idari olarak ibraz edilmesi veya doldurulması
          gereken tüm belgeleri ibraz etmeli veya doldurmalıdır.
        </ListItem>
        <ListItem>
          • Kullanıcı, terör örgütleri ile bağlantısından şüphelenilen kişiler,
          riskli ülkeler veya herhangi bir yaptırım listesinde yer alan
          kişilerle işlem gerçekleştirmemelidir.
        </ListItem>
        <ListItem>
          • Kullanıcı, doğrudan veya dolaylı olarak Platform’a, Norbi’ye, diğer
          Kullanıcılar’a veya üçüncü kişilere herhangi bir şekilde zarar
          verebilecek, onların haklarını kısıtlayacak, haklarını kullanmalarını
          engelleyecek, mevcut zararı arttıracak ya da karını azaltacak
          faaliyetlerde ve bu sonucu doğurabilecek davranışlarda bulunmamalıdır.
        </ListItem>
        <ListItem>
          • Kullanıcı, Platform’un işleyişine zarar vermemeli, emir ve işlem
          akışını bozmamalı veya tersine mühendislik, Platform kodlarına
          erişmeye ve bunları keşfetmeye çalışmak dahil diğer kötü niyetli
          faaliyetlerde bulunmamalıdır.
        </ListItem>
        <ListItem>
          • Kullanıcı, Platform’da yer alan başkalarına ait kişisel verileri
          kendi amaçlarıyla toplamamalı veya kullanmamalıdır.
        </ListItem>
        <ListItem>
          • Kullanıcı bir işlem gerçekleştirmeden önce o işlem ile ilgili fiyat,
          işlem miktarı, işlem ücreti, işlem türü, transfer yönü, ağ seçimi,
          gönderilecek cüzdan adresi, alıcının e-posta adresi, oranlar, uyarılar
          dahil işleme ilişkin bilgilendirme ve detayları dikkatlice okumalı ve
          gerçekleştireceği işlemin tüm unsurlarından emin olmalıdır.
        </ListItem>
        <ListItem>
          • Kullanıcı, Platform’da girişini yaptığı teknik bilgileri son derece
          dikkatli bir şekilde kontrol etmeli ve doğru şekilde girmelidir.
        </ListItem>
      </List>

      <Heading as="h2" size="md" mt={6}>
        5. İŞLEMLER
      </Heading>
      <Text mt={4}>
        5.1 Norbi, Platform’da yapılabilen işlem türlerini, işlem yapılabilen
        Kripto Varlıklar’ı ve işlem limitlerini kendi takdir yetkisine göre
        belirler.
      </Text>
      <Text mt={4}>
        5.2 Norbi, Platform üzerinden sağladığı hizmetler çerçevesinde
        Kullanıcı’dan işlem ücreti alma hakkına sahiptir (“İşlem Ücreti”).
        Norbi, İşlem Ücretleri’ni tek taraflı takdir yetkisine dayanarak
        belirleyecek ve Platform üzerinden duyuracaktır. Norbi, duyurduğu İşlem
        Ücretleri’nin detayları hakkında her zaman değişiklik yapma hakkını
        saklı tutar. İşlem Ücretleri’nde değişiklik yapılması durumunda güncel
        tutarlar değişikliğin yapıldığı tarihten sonra gerçekleşecek tüm
        işlemler bakımından geçerli olacaktır.
      </Text>
      <Text mt={4}>
        5.3 Kullanıcı, Platform üzerinden gerçekleşmiş işlemlerin geriye
        alınamayacağını ve İşlem Ücretleri’nin iade edilemeyeceğini kabul ve
        beyan eder.
      </Text>
      <Text mt={4}>
        5.4 Norbi, Kullanıcı’nın Platform’da gerçekleştirdiği tüm işlemlere
        ilişkin kayıtları saklayabilecek ve kanunen gerekmesi ya da talep
        üzerine yetkili kurum ve kuruluşlarla paylaşabilecektir. (Kişisel
        verilerinizin korunması bakımından lütfen 7. Maddeyi inceleyiniz.)
      </Text>
      <Text mt={4}>
        5.5 Platform’da yer alan Kripto Varlık İşlem Hizmetleri (“İşlem
        Hizmetleri”), Kripto Varlıklar’ın alınması veya satılmasını
        kolaylaştıran bir özellik olarak sağlanmaktadır. İşlem Hizmetleri,
        Kullanıcılar tarafından gönderilen alım ve satım emirlerinin
        eşleştirilmesinde ve ilgili işlemlerin gerçekleştirilmesinde yardımcı
        olur. İşbu Sözleşme’de açıkça belirtilmediği sürece, ne Norbi ne de
        Platform, İşlem Hizmetleri kapsamında Kullanıcılar’ı veya bu
        işlemlerdeki diğer katılımcıları temsil etmez. Norbi, Kullanıcılar
        arasında veya herhangi bir işlemle bağlantılı olarak ortaya çıkabilecek
        anlaşmazlıklar için hiçbir sorumluluk kabul etmez.
      </Text>
      <Text mt={4}>
        5.6 Platform’da yer alan Cüzdan Hizmeti, Kripto Varlıklar’a erişiminizi
        ve bunları transfer etmenizi kolaylaştırır. Cüzdan Hizmeti aracılığıyla
        erişilebilen Kripto Varlıklar üzerindeki kontrol her zaman yalnızca
        sizin üzerinizdedir. Norbi, Cüzdan'da yer alan Kripto Varlıklar üzerinde
        herhangi bir yetki, kontrol, sahiplik veya karar verme gücüne sahip
        değildir. Kripto Varlıklar’a erişme ve işlem yapma faaliyetlerini
        bağımsız olarak gerçekleştirirsiniz ve Kripto Varlıklarınız’ı kendi
        takdirinize bağlı olarak alternatif bir cüzdana taşıma ayrıcalığını
        saklı tutarsınız. Cüzdanınız aracılığıyla erişilebilen Kripto Varlıklar
        ile ilgili olarak, Norbi herhangi bir sorumluluk taşımaz ve söz konusu
        varlıkların transferini gerçekleştirme kapasitesine sahip değildir.
        Cüzdanınız’daki Kripto Varlıklar’ı transfer etme veya blok zincirinde
        diğer kayıtlı işlemleri başlatma yetkisi yalnızca size aittir. Bu
        kapsamda Hizmetler’i kullanırken Kripto Varlıklarınız’ın korunması,
        yönetimi ve bunlarla ilgili risklerden sorumlu olduğunuzu ve Norbi’nin
        bu konuda hiçbir yükümlülük veya sorumluluk taşımadığını kabul, beyan ve
        taahhüt edersiniz.
      </Text>
      <Text mt={4}>
        5.7 Teknik ve diğer kısıtlamalar nedeniyle, Platform’da görüntülenen
        Kripto Varlıklar’ın fiyatlarının ve oranlarının mevcut, gerçek zamanlı
        piyasa değerini temsil etmeyebileceğini kabul etmektesiniz.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        6. RİSKLER VE UYARILAR
      </Heading>
      <Text mt={4}>
        6.1 Platform’da yer alan içerikler bilgilendirme amaçlı olup sermaye
        piyasası araçlarının satın alınmasına yönelik bir çağrı veya yatırım
        danışmanlığı kapsamında değerlendirilemez ve hiçbir şekilde yatırım
        tavsiyesi teşkil etmez. Platform’da yer alan hiçbir unsur, herhangi bir
        yatırım aracının alım-satım önerisi ya da getiri vaadi olarak
        yorumlanamaz. Platform’daki hiçbir işleminizi Norbi tarafından yapılan
        bir çağrı, öneri, tavsiye, vaat veya danışmanlık kapsamında
        gerçekleştirmediğinizi ve gerçekleştirmeyeceğinizi kabul ve beyan
        edersiniz.
      </Text>
      <Text mt={4}>
        6.2 Norbi, Platform sağlayıcısı olup, Platform’daki Kripto Varlıklar’la
        yapılan işlemlerde taraf değildir. İşlem Hizmeti’nde listelenen Kripto
        Varlıklar’ın alış-satışını Kullanıcılar, Platform üzerinden kendi
        aralarında gerçekleştirirler. Platform’da yer alan Kripto Varlıklar’ın
        değerinin belirlenmesinde Norbi’nin kontrolü veya etkisi
        bulunmamaktadır. Değerler arz talep ilişkisine göre belirlenir ve bu
        değerlerin değişmesinden dolayı Norbi’nin hiçbir sorumluluğu yoktur.
        Kripto Varlıklar’ın değer değişiminden kaynaklanan tüm zarar ve kayıplar
        Kullanıcı’ya aittir.
      </Text>
      <Text mt={4}>
        6.3 Kripto Varlıklar’ın Platform’da listelenmesi, Cüzdan’a
        aktarılabilmesi veya Hizmetler’de kullanılabilmesi hiçbir şekilde Kripto
        Varlıklar’a ilişkin teknolojinin Norbi tarafından onaylandığı anlamına
        gelmez. Kripto Varlıklar’ın muteberliğine ilişkin hiçbir garanti
        vermemekteyiz.
      </Text>
      <Text mt={4}>
        6.4 Kripto Varlıklar’ın fiyatının dalgalanabileceğini ve aleyhe finansal
        risk teşkil edebileceğini göz önünde bulundurmalısınız. Hukuki
        düzenlemeler, piyasa manipülasyonu ve diğer öngörülemeyen sebepler
        Kripto Varlıklar’ın değerinde değişikliğe sebep olabilecektir. Bir alım
        kararı vermeden önce profesyonel danışmanınız ile irtibata geçmeniz ve
        mali durumunuz kapsamında risk seviyelerine ilişin bir değerlendirme
        yapmanız tavsiye edilir.
      </Text>
      <Text mt={4}>
        6.5 Norbi, Platform’un ve Hizmetler’in erişilebilir, kullanılabilir,
        kesintisiz ve hatasız olması adına makul ölçüde olan tüm önlemleri
        almaktadır ancak Norbi, Platform’u ve Hizmetler’i “olduğu gibi” ve
        “erişilebildiği hâliyle” sunmaktadır ve Platform’un 7/24 erişilebilir,
        kullanılabilir, kesintisiz ve hatasız olduğunu taahhüt etmemektedir.
        Norbi, Platform’un ve Hizmetler’in sürekli erişilebilir olacağına,
        sürekli gecikmesiz ve sorunsuz işleyeceğine, Platform’un sizin
        ihtiyaçlarınızı karşılayacağına, Platform’dan elde edebileceğiniz
        bilgilerin doğru olacağına veya Platform’daki hataların düzeltileceğine
        ilişkin bir garanti vermemektedir.
      </Text>
      <Text mt={4}>
        6.6 Kripto Varlıklar’ın işleyişini ve çalışmasını düzenleyen protokoller
        Norbi’nin kontrolünde olmayıp üçüncü kişiler tarafından oluşturulur ve
        değiştirilir. Norbi Kripto Varlıklar’ın işleyişini ve çalışmasını
        düzenleyen protokollerin her zaman çalışır durumda kalacağına,
        protokollerde değişiklik veya çatallanma olmayacağına dair herhangi bir
        garanti vermez.
      </Text>
      <Text mt={4}>
        6.7 Norbi, yürürlükteki kanunlar ve düzenlemeler, geçerli mahkeme kararı
        veya diğer ticari gereklilikler uyarınca Platform’da ve Hizmetler’de
        gerçekleştirilen işlemleri iptal etmeye yetkilidir. Norbi, Kullanıcı
        tarafından gerçekleştirilen işlemleri hiçbir gerekçe göstermeksizin
        gerçekleştirmeme veya iptal etme hakkına sahiptir.
      </Text>
      <Text mt={4}>
        6.8 Kullanıcı, gerçekleştirdiği Kripto Varlık işlemlerinden ve Kripto
        Varlıklar’ın Platform dışında bir adrese veya Platform dışında bir
        adresten Platform’a gönderilmesinden tek başına sorumludur. Bu işlemleri
        gerçekleştirirken girilmesi gereken bilgilerin hatalı girilmesi, adresin
        yanlış girilmesi veya ağ seçiminin hatalı yapılması dolayısıyla yaşanan
        kayıp ve zararlardan Norbi sorumlu değildir. Kullanıcı, Kripto
        Varlıklar’la ilgili işlemleri gerçekleştirebilmek için yeterli bilgi ve
        deneyime sahip olduğunu kabul eder. Kullanıcı, Kripto Varlıklar’la
        gerçekleştirilen işlemlerinin geriye alınamaz olması nedeniyle Kripto
        Varlık işlemlerinin hatalı yapılması durumunun Kripto Varlıklar’ın
        kaybolmasına neden olabileceğini bildiğini kabul ve beyan eder.
      </Text>
      <Text mt={4}>
        6.9. Norbi, Platform’da oluşabilecek kısa süreli ya da uzun süreli
        teknik arızalardan dolayı doğrudan veya dolaylı olarak doğan ve
        doğabilecek hiçbir zarardan sorumlu tutulamaz. İşlemlerin teknik hatalar
        sebebi ile ve/veya gerçekçi olmayan fiyatlardan gerçekleşmesi gibi
        hallerde Norbi, Platform’u ve kullanılan sistemleri düzeltmek ve doğru
        çalışmasını sağlamak adına, bu işlemleri iptal edebilir veya geri
        alabilir. İptal ve/veya geri alma halinde ilgili Kullanıcı Hesapları
        dondurulabilir, şayet bir ödeme yapıldı ise haksız yapılmış ödemenin
        iadesi istenebilir. İlgili bedellerin iade edilmemesi halinde Norbi her
        türlü hukuki yola başvurma hakkına sahiptir.
      </Text>
      <Text mt={4}>
        6.10 Hesap bilgileriniz, şifreleriniz, anahtarlarınız, yedek
        dosyalarınız ve güvenli Cüzdanınıza erişimi sağlayan tüm diğer kimlik
        doğrulama bilgileri ve dosyalarınızın korunmasından ve yedeklenmesinden
        yalnızca siz sorumlusunuz. Bu bilgiler size özel olup, Norbi’nin bu
        bilgilere bir erişimi bulunmamaktadır. Norbi'de Cüzdan’ı yedeklemenin
        iki yolu bulunmaktadır. Kullanıcı, yedek dosyasını kendi cihazına
        indirebilir ve saklayabilir, bu yedek dosyasını saklamak istemeyen
        Kullanıcılar ise bir pin kodu oluşturarak Cüzdan’ı yalnızca bu pin kodu
        ile erişilebilecek şekilde yedekleyebilir ve her yerden erişebilmek için
        Norbi sunucularında saklayabilir. Cüzdan erişimi sağlayan yedek
        dosyanızı yedeklememeniz, kaybetmeniz ve/veya oluşturduğunuz pin kodunu
        unutmanız ve/veya kaybetmeniz veya pin kodunun üçüncü kişiler tarafından
        her ne suretle olursa olsun ele geçirilmesi sonucunda Cüzdanınıza
        erişiminizi, geri dönüşü olmayacak şekilde, tamamen kaybedersiniz. Kendi
        cihazlarınızda yaptığınız bu işlemler hakkında Norbi’nin herhangi bir
        kontrolü bulunmamakta olup Norbi bu işlemlere ilişkin herhangi bir
        destek sağlayamamaktadır. Cüzdan’a erişimin yukarıdaki sayılan sebepler
        dahil olmak üzere herhangi bir sebeple kaybedilmesinden dolayı, Norbi
        olarak Cüzdanınıza erişimin tekrar sağlanması ve yaşanan kayıp, hasar ve
        hak taleplerine ilişkin herhangi bir sorumluluğumuz bulunmamaktadır.
        İlaveten, kendi hatanız sonucu veya internet yer sağlayıcısı şirketin
        sistemlerinden kaynaklı olarak veya Norbi’nin kontrolü dışında bulunan
        herhangi bir sebepten ortaya çıkan siber saldırı ve kripto para
        çalınması gibi zararlardan Norbi olarak herhangi bir sorumluluğumuz
        bulunmamaktadır. Tüm bu koşulları kabul ettiğinizi ve doğabilecek
        sonuçlardan Norbi’yi sorumlu tutmayacağınızı beyan edersiniz.
      </Text>
      <Text mt={4}>
        6.11. Risklere ilişkin konular hakkında bilgi edinmek amacıyla işbu
        Sözleşme’nin ayrılmaz bir parçası olan Risk Açıklama Bildirimi’ni
        okuduğunuzu ve anladığınızı kabul edersiniz.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        7. KİŞİSEL VERİLERİNİZİN KORUNMASI
      </Heading>
      <Text mt={4}>
        Kişisel verilerinizin korunmasını önemsiyoruz. Bu kapsamda kişisel
        verilerinizi 6698 sayılı Kişisel Verilerin Korunması Kanunu’na ve ilgili
        diğer mevzuata uygun olarak işlemekteyiz. İşlediğimiz kişisel
        verileriniz, işleme amaçlarımız, dayandığımız hukuki sebepler ve ilgili
        kişi olarak kullanabileceğiniz hakları öğrenmek için lütfen Aydınlatma
        Metnimiz’i inceleyiniz.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        8. FİKRİ MÜLKİYET HAKLARI
      </Heading>
      <Text mt={4}>
        8.1 Platform’da ve Hizmetler’de yer alan tüm teknoloji, yazılım, içerik,
        logo, tasarım, model, yazı, fotoğraflar, resimler, videolar, ara yüzler,
        markalar, her türlü öneri, fikir, geliştirme talebi, geri bildirim ve
        ilgili tüm fikri mülkiyet hakları dahil olmak üzere tüm hak, unvan ve
        menfaatler (“Fikri Mülkiye Hakları”) yalnızca Norbi’ye ve lisans
        verenlerine aittir. Platform’daki hiçbir şey, burada açıkça
        belirtilenler dışında, herhangi bir Kullanıcıya Norbi’nin veya herhangi
        bir üçüncü şahsın unvanı, mülkiyet hakları ve/veya fikri mülkiyet
        haklarıyla ilgili herhangi bir lisans verildiği şeklinde
        yorumlanmayacaktır.
      </Text>
      <Text mt={4}>
        8.2 Kullanıcı, Norbi’nin yazılı izni olmaksızın Fikri Mülkiyet
        Hakları’nı kullanamaz, kopyalamaz, çoğaltamaz, paylaşamaz, dağıtamaz,
        işleyemez, değiştiremez veya taklitlerini üretemez. Kullanıcı’nın,
        Norbi’nin Fikri Mülkiyet Hakları’nı ihlal edecek şekilde davranması
        durumunda, Kullanıcı, Norbi’nin tüm doğrudan ve dolaylı zararları ile
        masraflarını tazmin etmekle yükümlüdür.
      </Text>
      <Text mt={4}>
        8.3. İşbu Sözleşme’yi kabul ederek, Platform’u, Hizmetler’i veya
        sağlanan herhangi bir veri veya içeriği veya herhangi bir bölümünü
        değiştirmemeyi, uyarlamamayı, çoğaltmamayı, tercüme etmemeyi veya
        bunlardan türev çalışmalar oluşturmamayı veya Platform’un kaynak kodunu
        tersine mühendislik, kaynak koda dönüştürme veya parçalarına ayırma
        girişiminde bulunmamayı taahhüt edersiniz.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        9. FESİH
      </Heading>

      <Text mt={4}>
        9.1 İşbu Sözleşme’yi Hesap’ınızı silerek sona erdirebilirsiniz. Hak
        kaybına uğramamanız adına, Hesap’ınızı silmek için Hesap’ınızda bir
        Kripto Varlık yer almamalıdır. Hesap’ınızı silme talebinden önce tüm
        Kripto Varlıklar’ı Hesap’ınızdan çekmeniz gerekmektedir. Aksi durumda
        Hesap’ınızın silinemeyeceğini kabul ve beyan edersiniz.
      </Text>
      <Text mt={4}>
        9.2 Norbi, işbu Sözleşme’yi, aşağıda belirtilen durumlardan en az
        birinin gerçekleşmesi de dahil olmak üzere herhangi bir nedenle, kendi
        takdirine bağlı olarak ve herhangi bir tazminata tabi olmadan
        sonlandırabilecektir. Böyle bir durumda Norbi’nin, herhangi bir zamanda
        Hesap’ınızı silme, Hesap’ınıza erişiminizi durdurma, askıya alma ve son
        verme hakkı olduğunu kabul ve beyan edersiniz. Bu durum, işbu Sözleşme
        kapsamındaki yükümlülüklerinizin sona ereceği anlamına gelmez.
        Sözleşme’ye aykırı davranmanız durumunda Norbi’nin tazminat talep etme
        hakkı saklıdır.
      </Text>
      <List mt={4} spacing={2}>
        <ListItem>
          a. Norbi'nin Hesap'ın güvenliğini korumak için gerekli görmesi
          halinde;
        </ListItem>
        <ListItem>
          b. Norbi’nin tamamen kendi takdirine bağlı olarak Kullanıcı’nın işbu
          Sözleşme’yi veya Hesap’ın güvenlik gereksinimlerini ihlal ettiğini
          veya kara para aklama, terör finansmanı, dolandırıcılık veya diğer
          yasa dışı faaliyetler dahil ancak bunlarla sınırlı olmamak üzere
          şüpheli, yetkisiz veya hileli olduğunu düşündüğü bir işlem şüphesinin
          varlığı halinde;
        </ListItem>
        <ListItem>
          c. Kullanıcı tarafından sağlanan bilgilerin doğrulanamaması veya
          doğruluğunun kanıtlanamaması halinde;
        </ListItem>
        <ListItem>
          d. Norbi’nin, tamamen kendi takdirine bağlı olarak, Kullanıcı’nın
          eylemlerinin Kullanıcı, Platform veya Platform’un diğer Kullanıcılar’ı
          için yasal sorumluluk doğurabileceğine dair makul bir inanca sahip
          olması halinde;
        </ListItem>
        <ListItem>
          e. Norbi’nin faaliyetlerini durdurmaya karar vermesi veya Platform
          veya herhangi bir bölümü tarafından sağlanan herhangi bir hizmeti veya
          seçeneği başka bir şekilde durdurması halinde;
        </ListItem>
        <ListItem>
          f. Norbi’nin, kendi takdirine bağlı olarak, Platform’un sürekliliği
          için risk teşkil eden bir hususu tespit etmesi halinde;
        </ListItem>
        <ListItem>
          g. Herhangi bir resmi makam tarafından talimatlandırılması halinde
          veya yürürlükteki yasaların gerektirmesi halinde; veya
        </ListItem>
        <ListItem>
          h. Norbi, tamamen kendi takdirine bağlı olarak, Hesap’ın, Platform’un
          veya Sözleşme’nin feshedilmesinin veya askıya alınmasının gerekli
          olduğunu belirlerse.
        </ListItem>
      </List>
      <Text mt={4}>
        9.3 Norbi, Hesap’ı silinmiş, Hesap’ına erişimi durdurulmuş, askıya
        alınmış veya sona erdirilmiş bir Kullanıcı’nın yeni bir Hesap açmasını
        engelleme hakkına sahiptir.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        10. DEĞİŞİKLİKLER
      </Heading>
      <Text mt={4}>
        10.1 Norbi her zaman işbu Sözleşme’de değişiklik yapma, Platform’un
        kullanılmasına dair ilave kurallar belirleme ve bu kuralları güncelleme
        hakkını saklı tutar.
      </Text>
      <Text mt={4}>
        10.2 Güncellenmiş Sözleşme, Platform’da yayımlanacaktır. Norbi,
        güncellenmiş Sözleşme’yi, Kullanıcı aleyhine esaslı bir değişiklik
        içermesi durumunda Kullanıcılar’ın e-posta adresine göndererek de
        bilgilendirme yapabilecektir. Lütfen tüm güncelleme veya
        değişikliklerden haberdar olmak için Platform’da yer alan Kullanıcı
        Sözleşmesi’ni düzenli olarak kontrol ediniz.
      </Text>
      <Text mt={4}>
        10.3 Aksi belirtilmedikçe, güncellenmiş Sözleşme, Platform’da
        yayımlandığı tarihten itibaren geçerli olacaktır. Bu tarihten itibaren
        Platform’u kullanmaya devam etmeniz güncellenmiş Sözleşme’yi kabul
        ettiğiniz anlamına gelir.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        11. MÜCBİR SEBEP
      </Heading>
      <Text mt={4}>
        Platform’un veya Hizmetler’in çalışmasının kısmen veya tamamen, geçici
        veya daimi olarak durmasına sebep olan yangın, patlama, fırtına, sel,
        deprem, göç, salgın veya diğer doğal felaketler; ambargo, devlet
        müdahalesi, kanuni engeller, isyan, işgal, savaş, salgın, seferberlik,
        grev, lokavt, iş eylemleri veya boykotlar; siber saldırı, iletişim
        sorunları, altyapı ve internet arızaları, blok zincir ağlarındaki
        kesintiler, hatalar, hard fork'lar, sisteme ilişkin iyileştirme veya
        yenileştirme çalışmaları ve bu sebeple meydana gelebilecek arızalar ve
        elektrik ve internet kesintileri, yasal değişiklikler veya Norbi’nin
        kontrolü dışında gerçekleşen, kusurundan kaynaklanmayan ve makul olarak
        öngörülemeyecek diğer haller sebebiyle, Norbi, Platform’u sunamaması
        veya işbu Sözleşme kapsamındaki yükümlülüklerini ifa edememesi veya
        geciktirmesinden sorumlu olmayacaktır. Kullanıcı’nın bu ifaların
        engellenmesi veya gecikmesi nedeniyle uğradığı zararı Şirket’ten talep
        etme hakkı olmayacaktır.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        12. ÜÇÜNCÜ TARAF İÇERİKLERİ VE BAĞLANTILARI
      </Heading>
      <Text mt={4}>
        12.1 Platform; üçüncü taraflarca üretilmiş, üçüncü tarafların sahip
        olduğu veya üçüncü taraflarca işletilen içerikler, hizmetler veya bu
        içerik ve hizmetlere yönlendiren bağlantılar ile kamuya açık
        kaynaklardan toplanan veriler (“Üçüncü Taraf İçerikler”)
        içerebilecektir. Norbi’nin Üçüncü Taraf İçerikler’de kontrolü, denetimi
        veya onayı bulunmamaktadır ve Norbi Üçüncü Taraf İçerikler’den
        kesinlikle sorumlu değildir. Üçüncü Taraf İçerikler’in kullanımı ile
        ilgili her türlü risk tarafınıza aittir. Lütfen Üçüncü Taraf
        İçerikler’in kullanımı ile ilgili bu üçüncü tarafların kural, şart ve
        koşullarını inceleyiniz.
      </Text>
      <Text mt={4}>
        12.2 Üçüncü Taraf İçerikleri’ndeki veya Üçüncü Taraf İçerikleri ile
        ilgili tüm mülkiyet, unvan ve fikri mülkiyet hakları ilgili Üçüncü Taraf
        İçerik sağlayıcısına ait olacaktır. Platform’daki hiçbir şey, burada
        açıkça belirtilmedikçe, herhangi bir kullanıcıya Norbi’nin veya herhangi
        bir üçüncü tarafın mülkiyet, unvan ve/veya fikri mülkiyet hakları için
        bir lisans verdiği şeklinde yorumlanmamalıdır.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        13. TAZMİNAT
      </Heading>
      <Text mt={4}>
        13.1 Yürürlükteki yasaların izin verdiği azami ölçüde, işbu Sözleşme’yi,
        herhangi bir yasayı veya üçüncü bir kişinin haklarını ihlal etmeniz veya
        Platform’da sizin tarafınızdan gerçekleştirilen işlemler sonucu doğrudan
        veya dolaylı olarak Norbi’nin veya üçüncü tarafların zarara uğraması
        durumunda bu kayıpları, zararları, maliyetleri, masrafları ve ilgili
        tarafların taleplerini tazmin etmeyi kabul etmektesiniz.
      </Text>
      <Text mt={4}>
        13.2 Gerçekleştirilen hatalı işlemler nedeniyle veya işbu madde uyarınca
        tazminat olarak ödemeniz gereken miktarlar dahil İşbu Sözleşme veya
        herhangi bir yasal sebepten doğan alacakları Norbi’ye ödememeniz
        durumunda ilgili miktara karşılık gelecek Varlığı, Hesapları’nızdan
        doğrudan tahsil etme hakkına sahip olduğumuzu kabul, beyan ve taahhüt
        edersiniz.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        14. SORUMLULUĞUN SINIRLANDIRILMASI
      </Heading>
      <Text mt={4}>
        (i) Platform’u kullanırken işbu Sözleşme’yi ihlal etmişseniz, (ii) zarar
        sizin veya üçüncü kişilerin eylemi nedeniyle oluşmuşsa, (iii) zararın
        oluşmasında veya artmasında hafif ihmal dahil sizin veya üçüncü
        tarafların kusuru bulunuyorsa, (iv) sizi zararın oluşabileceğine dair
        zararın meydana gelmesinden önce uyarmışsak, (v) Platform’u kullanırken
        hukuka veya ahlaka aykırı davranışlarda bulunmuşsanız, veya (vi) zarar
        Norbi’nin hafif kusurundan kaynaklanıyorsa Norbi, Norbi’nin yönetim
        kurulu, hissedarları, müdürleri, çalışanları, iş ortakları, grup
        şirketleri, tedarikçileri, temsilcilerinin hiçbir şekilde
        zararlarınızdan sorumlu olmayacağını kabul, beyan ve taahhüt edersiniz.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        15. UYGULANACAK HUKUK VE YETKİ
      </Heading>
      <Text mt={4}>
        15.1. İşbu Sözleşme’nin yorumlanması ve uygulanması Türkiye Cumhuriyeti
        kanunlarına tabidir.
      </Text>
      <Text mt={4}>
        15.2. İşbu Sözleşme’nin yorumlanması ve uygulanmasından doğabilecek her
        türlü uyuşmazlıkların çözümünde İstanbul (Çağlayan) Mahkemeleri yetkili
        olacaktır.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        16. MUHTELİF HÜKÜMLER
      </Heading>
      <Text mt={4}>
        16.1 İşbu Sözleşme kapsamındaki bir hükmün geçersizliği hiçbir şekilde
        diğer hükümlerin geçerliliğini etkilemeyecektir. İşbu Sözleşme
        kapsamındaki herhangi biri madde bir kanun veya düzenleme sebebiyle
        uygulanamaz veya geçersiz hale gelirse Sözleşme’nin kalanı bundan
        etkilenmeyecektir.
      </Text>
      <Text mt={4}>
        16.2 İşbu Sözleşme ile bağlantılı uyuşmazlıklarda Norbi’ye ait
        elektronik ve sistem kayıtları, ticari kayıtlar, defter kayıtları,
        mikrofilm, mikrofiş ve bilgisayar kayıtları, blokzincir ağındaki
        kayıtlar, soğuk veya sıcak cüzdanlar, bağlayıcı, kesin ve münhasır delil
        teşkil edecektir.
      </Text>
      <Text mt={4}>
        16.3 Norbi, Kullanıcı ile üyelik oluşturma anında bildirilen elektronik
        posta adresi vasıtasıyla veya telefon numarasına arama yapmak ve SMS
        göndermek suretiyle iletişim kurabilir. Kullanıcı, elektronik posta
        adresini ve telefon numarasını güncel tutmakla yükümlü olduğunu kabul
        eder. Kullanıcı, Sözleşme’nin imzalanması sırasında sağlanan bilgilerde
        herhangi bir değişiklik olması halinde, söz konusu bilgileri derhal
        güncellemekle yükümlüdür. Bu bilgilerin eksik veya gerçeğe aykırı olarak
        verilmesi ya da güncel olmaması nedeniyle Platform’a erişim
        sağlanamamasından veya Platform’dan faydalanılamamasından Norbi hiçbir
        şekilde sorumlu değildir.
      </Text>
      <Text mt={4}>
        16.4 Norbi’nin haklarından herhangi birisini kullanmaması veya geç
        kullanması, bu haktan feragatettiği anlamına gelmeyecektir.
      </Text>
      <Text mt={4}>
        16.5 On altı maddeden (16) ibaret işbu Sözleşme’nin tüm maddelerini ve
        alt fıkra ve bentlerini ayrı ayrı okuyup anladığınızı, Sözleşme’nin
        bütün içeriğini ve bütün hükümlerini onayladığınızı kabul, beyan ve
        taahhüt edersiniz. Sözleşme’yi kabul etmeyen Kullanıcı’nın Platform’a
        üye olması ve Platform’un hizmetlerinden faydalanması mümkün
        olmayacaktır.
      </Text>
    </Box>
  );
};

export default UserAgreement;

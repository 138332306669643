import { Box, Heading, Text, List, ListItem, Link } from "@chakra-ui/react";

const KvkkPolicy = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        YENKİDO BİLİŞİM HİZMETLERİ TİCARET ANONİM ŞİRKETİ
      </Heading>
      <Heading as="h2" mb={8} textAlign="center">
        Gizlilik Politikası ve Aydınlatma Metni
      </Heading>

      <Text mb={4}>Son Güncelleme: 05.08.2024</Text>

      <Heading as="h2" size="md" mt={6}>
        1. Veri Sorumlusu
      </Heading>
      <Text mt={4}>
        Halaskargazi Cad. No: 38-66E İç Kapı No: 215, Nişantaşı Lotus, 34371 Şişli/İstanbul adresinde mukim
        Yenkido Bilişim Hizmetleri Ticaret Anonim Şirketi ("Norbi", "Şirketimiz" veya "biz") olarak, internet
        sitelerimiz (
        <Link href="https://norbi.app" isExternal>
          https://norbi.app
        </Link>
        ,{" "}
        <Link href="https://web.norbi.app" isExternal>
          https://web.norbi.app
        </Link>
        ,{" "}
        <Link href="https://polyverse.life" isExternal>
          https://polyverse.life
        </Link>
        ) ve mobil uygulamamız (bundan sonra hep birlikte "Platform") üzerinden siz kullanıcılarımıza
        kripto varlık işlem platformunu, saklama ve cüzdan hizmetlerini sunmaktayız ("Hizmetler") ve bu kapsamda birtakım kişisel verilerinizi
        işlemekteyiz.
      </Text>

      <Text mt={4}>
        İşbu Gizlilik Politikası ve Aydınlatma Metni, Hizmetlerimiz aracılığıyla ve 6698 sayılı Kişisel Verilerin
        Korunması Kanunu ("Kanun") kapsamında veri sorumlusu sıfatıyla işlediğimiz kişisel verileriniz, kişisel
        verileri işleme amaçlarımız, yöntemlerimiz, dayandığımız hukuki sebepler, kişisel verilerinizin aktarılması
        ve haklarınız hakkında Kanun hükümlerine uygun olarak aydınlatma yükümlülüğü kapsamında sizi
        bilgilendirmek amacıyla hazırlanmıştır.
      </Text>

      <Text mt={4}>
        Gizlilik Politikası ve Aydınlatma Metni, Norbi tarafından yayımlandığı tarih itibarıyla geçerli olacaktır. Norbi,
        Gizlilik Politikası ve Aydınlatma Metni'nde gerekli olduğu takdirde her zaman değişiklik yapabilir.
        Yapılacak değişiklikler, Gizlilik Politikası ve Aydınlatma Metni'nin "
        <Link href="https://norbi.app/" isExternal>
          https://norbi.app/
        </Link>
        " adresinde yayımlanmasıyla birlikte derhal geçerlilik kazanır.
      </Text>

      <Text mt={4}>
        Platform'da kullandığımız çerezler ve çerezler yoluyla işlediğimiz kişisel verileriniz hakkında bilgi sahibi
        olmak için Çerez Politikamızı inceleyiniz.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        2. İşlediğimiz Kişisel Verileriniz
      </Heading>
      <Text mt={4}>
        Norbi olarak Hizmetlerimiz aracılığıyla aşağıdaki kişisel verilerinizi işleyebilmekteyiz:
      </Text>
      <List mt={4} spacing={4}>
        <ListItem>
          • Ad, soyad, cinsiyet, doğum yeri, T.C. kimlik numarası, doğum tarihi, anne ve baba adı, anne kızlık
          soyadı, medeni hal, vatandaşlık, kimlik belgenizin türü ve numarası, kimlik belgenizde,
          pasaportunuzda, sürücü belgenizde ve yabancı kimlik belgenizde yer alan bilgileriniz dahil kimlik
          bilgileriniz;
        </ListItem>
        <ListItem>
          • E-posta adresi, telefon numarası, ikamet adresi, şehir/bölge, kayıtlı elektronik posta adresi, sosyal
          medya hesabınız dahil iletişim bilgileriniz;
        </ListItem>
        <ListItem>
          • Platform'da gerçekleştirdiğiniz işlemler, alış ve satışlarınız, çağrı merkezi kayıtlarınız, sipariş, talep,
          şikayet ve öneri bilgileriniz, ticari elektronik ileti tercihiniz dahil müşteri işlem bilgileriniz;
        </ListItem>
        <ListItem>
          • IP adres bilgileriniz, Platform giriş, ziyaret, geçirilen süre ve hareket bilgileriniz, Platform'a giriş
          yaptığınız ve eriştiğiniz cihaz ve tarayıcı bilgileriniz, güvenlik anahtarınız, API anahtarlarınız, cüzdan
          adresiniz, Platform kullanıcı bilgileriniz dahil işlem güvenliği bilgileriniz;
        </ListItem>
        <ListItem>
          • Kara para aklamanın önlenmesi ve yolsuzlukla mücadele politikaları gereğince işlediğimiz bilgiler,
          ticari, teknik, idari risklerin yönetilmesi için işlenen bilgiler, şüpheli işlem bilgileriniz dahil risk
          yönetimi bilgileriniz;
        </ListItem>
        <ListItem>
          • İşlem yaptığınız dijital varlıklar, işlem meblağları, kripto cüzdanınızdaki dijital varlıklar, cüzdan
          bakiyeniz, kripto cüzdanınızdan gerçekleştirdiğiniz yatırma ve çekme işlemleri, finansal hareketlilik
          ve performans bilgileri, banka hesap bilgileri, vergi kimlik numaranız dahil finans bilgileriniz;
        </ListItem>
        <ListItem>
          • İş ve mesleğinize ilişkin bilgiler dahil mesleki deneyim bilgileriniz;
        </ListItem>
        <ListItem>
          • Olası uyuşmazlıklara ve kamu kurumu taleplerine ilişkin bilgiler dahil hukuki işlem bilgileriniz;
        </ListItem>
        <ListItem>
          • Beğenilerinizi gösteren raporlar ve değerlendirmeler, hedefleme bilgileri, çerez kayıtları, veri
          zenginleştirme faaliyetleri sayesinde türetilen bilgiler, anketler, kampanyalar ve doğrudan
          pazarlama çalışmaları neticesinde elde edilen bilgiler ve değerlendirmeler dahil pazarlama
          bilgileriniz;
        </ListItem>
        <ListItem>
          • Fotoğrafınız dahil görsel ve işitsel kayıtlarınız.
        </ListItem>
      </List>

      <Heading as="h2" size="md" mt={6}>
        3. İşleme Amaçları
      </Heading>
      <Text mt={4}>
        İşbu Gizlilik Politikası ve Aydınlatma Metni'nin "İşlediğimiz Kişisel Verileriniz" başlıklı 2. bölümünde
        belirtilen kişisel verileriniz "Hukuki Sebeplerimiz" başlıklı 6. bölümde sayılan hukuki sebeplere bağlı
        olarak sadece belirli, açık ve meşru amaçlarla ve hukuka ve dürüstlük kurallarına uygun olarak
        işlenecektir. Şirketimiz kişisel verilerinizi işleme amacıyla bağlantılı, sınırlı ve ölçülü olarak işlemeye ve
        işlediği verilerin doğru ve güncel olmasına özen gösterecektir.
      </Text>
      <Text mt={4}>
        Şirketimiz, kişisel verilerinizi aşağıdaki amaçlar ile sınırlı olarak işleyecektir:
      </Text>
      <List mt={4} spacing={4}>
        <ListItem>
          • Kullanıcıların Platform'u ziyaret edebilmesi ve Platform'a üye olabilmesi;
        </ListItem>
        <ListItem>
          • Kullanıcıların Platform'daki özellikleri kullanarak kripto varlık alış, satış, cüzdan ve saklama
          hizmetlerinden faydalanabilmesi;
        </ListItem>
        <ListItem>
          • Platform'un ve kullanıcı hesaplarının güvenliğinin sağlanabilmesi;
        </ListItem>
        <ListItem>
          • Bize iletilen soruları, talepleri, şikayetleri ve diğer iletişimleri değerlendirebilmek ve bunlara cevap
          verebilmek;
        </ListItem>
        <ListItem>
          • Kullanıcıların Platform'u ve Hizmetler'i nasıl kullandıklarını anlayabilmek ve Hizmetlerimiz'i analiz
          etmek, geliştirmek, özelleştirmek ve kişiselleştirmek;
        </ListItem>
        <ListItem>
          • Platform'da alım, satım ve operasyon süreçleri ile satış sonrası destek süreçlerini yönetmek;
        </ListItem>
        <ListItem>
          • Platform trafik bilgilerini tutmak;
        </ListItem>
        <ListItem>
          • Platform'da yaşanabilecek sorunları ve hataları çözmek ve düzeltmek;
        </ListItem>
        <ListItem>
          • Tanıtım, pazarlama ve reklam faaliyetlerini gerçekleştirebilmek ve bu faaliyetlerin etkinliğini
          ölçebilmek;
        </ListItem>
        <ListItem>
          • Platform bilgilendirmeleri, duyuru ve iletişim faaliyetlerini yürütmek;
        </ListItem>
        <ListItem>
          • Etkinlikler, yarışmalar, özel teklifler, bülten, anket, kampanya amaçlı tarafınıza ticari elektronik ileti
          göndermek;
        </ListItem>
        <ListItem>
          • İnceleme, soruşturma, raporlama, analiz ve denetim çalışmaları yürütmek;
        </ListItem>
        <ListItem>
          • Kanuni ve hukuki yükümlülüklerimize uyum sağlayabilmek;
        </ListItem>
        <ListItem>
          • Hukuki süreçlerin takip edilebilmesi, ileride doğabilecek uyuşmazlıklarda delil olarak
          kullanılabilmesi, arabuluculuk, icra ve takip gibi hukuki süreçlerde hak ve menfaatlerin korunması,
          Platform'a erişim ve kullanım kapsamında şüpheli/hukuka aykırı işlemlerin tespiti ve Şirketimiz'in
          kendisini hukuken koruyabilmesi;
        </ListItem>
        <ListItem>
          • 5549 sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında Kanun ve 6415 sayılı Terörizmin
          Finansmanının Önlenmesi Hakkında Kanun kapsamında yasal yükümlülüklerimize uymak, bu
          anlamda kimliğinizi tespit etmek, şüpheli işlem bildirimi yapmak, bilgi ve belgeleri muhafaza ve
          gerektiğinde hukuken yetkili kişilere ibraz edebilmek, ilgili kamu kurum ve kuruluşlarına bilgi
          vermek ve hukuken gerekli tedbirleri almak.
        </ListItem>
        <ListItem>
          • Platform'da sunulan ürün ve hizmetlerin geliştirilebilmesi ve bu kapsamda analizlerin, müşteri ilişki
          yönetiminin, raporlamaların yapılabilmesini ve sonraki talep ve organizasyonlarda
          kullanılabilmesini, kampanya, promosyon ve duyuruların yapılabilmesi, kullanım amaçları ve
          ihtiyaçlarımıza göre özelleştirilerek pazarlama faaliyetlerinin gerçekleştirilmesi dâhil ve bunlarla
          sınırlı olmaksızın, hizmetlerinin yürütülmesi, geliştirilmesi ve müşteri memnuniyetinin
          gerçekleştirilebilmesi.
        </ListItem>
      </List>

      <Heading as="h2" size="md" mt={6}>
        4. Kişisel Verilerin Aktarılması
      </Heading>
      <Text mt={4}>
        Kişisel verileriniz, işbu Gizlilik Politikası ve Aydınlatma Metni'nin 3. maddesinde açıklanan amaçlarla
        Kanun'un 8. ve 9. maddeleri kapsamında kişisel verilerin aktarılmasına ilişkin kurallara uygun olarak ve
        Kanun'un 5. maddesinde düzenlenen veri işleme şartları kapsamında; yurt içinde ve yurt dışında bulunan
        tedarikçilerimizle, iş ortaklarımızla, yetkili kamu kurum ve kuruluşlarıyla ve hukuken yetkili kişilerle
        paylaşılabilecektir.
      </Text>
      <Text mt={4}>
        Örneğin, kişisel verileriniz aşağıdaki süreçlerde üçüncü kişilere aktarılabilecektir:
      </Text>
      <List mt={4} spacing={4}>
        <ListItem>
          • Platform'un ve hesabınızın güvenliğinin sağlanması, kimlik doğrulama süreçlerinin yürütülmesi ve
          sunucu barındırma hizmetinin alınması amaçlarıyla tedarikçilerimizle paylaşılabilecektir.
        </ListItem>
        <ListItem>
          • Analiz çalışmalarının yürütülmesi, Hizmetlerimiz'i geliştirmek, özelleştirmek ve kişiselleştirmek
          amacıyla iş ortaklarımızla paylaşılabilecektir.
        </ListItem>
        <ListItem>
          • Kanuni veya hukuki yükümlülüklerimize uyum sağlamak ve söz konusu olabilecek herhangi bir
          uyuşmazlık ile ilgili süreçlerin yürütülmesi amacıyla yetkili kamu kurum ve kuruluşları ve hukuken
          yetkili kişilerle paylaşılabilecektir.
        </ListItem>
        <ListItem>
          • Suç gelirlerinin aklanmasının ve terörizmin finansmanının önlenmesi kapsamında bilgi, belge
          sağlanması ve ibraz yükümlülüklerimize uymak amacıyla Mali Suçları Araştırma Kurulu ve Ticaret
          Bakanlığı dahil yetkili kamu kurum ve kuruluşlarıyla paylaşılabilecektir.
        </ListItem>
      </List>
      <Text mt={4}>
        Kişisel verileriniz, Kanun'un 9. maddesinde belirtilen usul ve esaslar ile Kişisel Verileri Koruma Kurulu
        kararları çerçevesinde yurt dışına aktarılabilir. Bu çerçevede kişisel verileriniz, Şirketimiz'in idaresi,
        Şirketimiz tarafından Hizmetler'in sunulabilmesi kapsamındaki işlerin yürütülmesi, operasyonel
        gerekliliklerin yerine getirilebilmesi ve Şirket politikalarının uygulanması amaçlarıyla sınırlı olmak üzere,
        Platform uygulamalarının ve yazılımlarının veri tabanlarının yurt dışında olması nedeniyle;
      </Text>
      <List mt={4} spacing={4}>
        <ListItem>• Açık rızanızın bulunması, veya</ListItem>
        <ListItem>
          • Verilerin aktarıldığı ülkede Kişisel Verileri Koruma Kurulu kararı uyarınca yeterli koruma bulunması;
          veya
        </ListItem>
        <ListItem>
          • Verilerin aktarıldığı ülkede yeterli koruma bulunmaması durumunda Şirketimiz'in ilgili yabancı
          ülkedeki veri sorumlusu ile birlikte yeterli korumayı yazılı olarak taahhüt etmesi ve Kişisel Verileri
          Koruma Kurulu'nun izninin alınması halinde
        </ListItem>
      </List>
      <Text mt={4}>yurt dışına aktarılabilmektedir.</Text>

      <Heading as="h2" size="md" mt={6}>
        5. Kişisel Verilerinizi Toplama Yöntemimiz
      </Heading>
      <Text mt={4}>
        Kişisel verilerinizi, aşağıda belirtilen elektronik ortamlardan otomatik veya otomatik olmayan yollarla
        topluyoruz:
      </Text>
      <List mt={4} spacing={4}>
        <ListItem>• Platform (internet sitesi ve mobil uygulama),</ListItem>
        <ListItem>• Online form,</ListItem>
        <ListItem>• Çerezler (Cookies) ve benzer teknolojiler,</ListItem>
        <ListItem>• E-posta,</ListItem>
        <ListItem>
          • Size Hizmetler'i sağlarken birlikte çalıştığımız iş ortaklarımız ve hizmet aldığımız tedarikçilerimiz,
        </ListItem>
        <ListItem>• Blockzincir ağları.</ListItem>
      </List>

      <Heading as="h2" size="md" mt={6}>
        6. Hukuki Sebeplerimiz
      </Heading>
      <Text mt={4}>
        Toplanan kişisel verileriniz aşağıdaki hukuki sebeplere dayanarak işlenebilecektir:
      </Text>
      <List mt={4} spacing={4}>
        <ListItem>
          • Kanun'un 5/1 maddesi uyarınca ilgili kişinin açık rıza vermesi.
          <Text mt={2}>
            - Örneğin, kişisel verilerinizin size etkinlikler, yarışmalar, özel teklifler, tanıtım, pazarlama, bülten,
            anket amaçlı ticari elektronik ileti gönderilmesi amacıyla işlenmesi.
          </Text>
        </ListItem>
        <ListItem>
          • Kanun'un 5/2(a) bendi uyarınca kanunlarda açıkça öngörülmesi.
          <Text mt={2}>
            - Örneğin, kişisel verilerinizin 5549 sayılı Suç Gelirlerinin Aklanmasının Önlenmesi Hakkında
            Kanun ve 5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar
            Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun'daki yükümlülüklerimize uyum
            sağlayabilmek amacıyla işlenmesi.
          </Text>
        </ListItem>
        <ListItem>
          • Kanun'un 5/2(c) bendi uyarınca bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
          olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması.
          <Text mt={2}>
            - Örneğin, kişisel verilerinizin Platform'u ziyaret edebilmeniz, üye olabilmeniz, Platform'daki
            özellikleri kullanarak kripto varlık hizmetlerinden faydalanabilmeniz amacıyla işlenmesi.
          </Text>
          <Text mt={2}>
            - Örneğin, kişisel verilerinizin bize iletilen sorulara, taleplere, şikayetlere ve diğer iletişimlere
            cevap verebilmek amacıyla işlenmesi.
          </Text>
        </ListItem>
        <ListItem>
          • Kanun'un 5/2(ç) bendi uyarınca hukuki yükümlülüğümüzün yerine getirilmesi için zorunlu olması.
          <Text mt={2}>
            - Örneğin, suç gelirlerinin aklanmasının ve terörizmin finansmanının önlenmesi kapsamında
            belgelerin talep edildiğinde ibrazı yükümlülüğümüze uymak amacıyla kişisel verilerinizin Mali
            Suçları Araştırma Kurulu dahil yetkili kamu kurum ve kuruluşlarıyla paylaşılması.
          </Text>
        </ListItem>
        <ListItem>
          • Kanun'un 5/2(e) bendi uyarınca bir hakkın tesisi, kullanılması ve korunması için veri işlemenin
          zorunlu olması.
          <Text mt={2}>
            - Örneğin, kişisel verilerin Şirket'e gönderilen ihtarnameleri, davaları ve diğer hukuki süreçleri
            takip edebilmek ve bunlara cevap verebilmek amacıyla işlenmesi.
          </Text>
          <Text mt={2}>
            - Örneğin, kişisel verilerin uyuşmazlık halinde Şirket'in kendini koruyabilmesi amacıyla işlenmesi.
          </Text>
        </ListItem>
        <ListItem>
          • Kanun'un 5/2(f) bendi uyarınca ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
          veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması.
          <Text mt={2}>
            - Örneğin, kişisel verilerinizin, Platform'un ve hesabınızın güvenliğinin sağlanabilmesi, hesabınıza
            yetkisiz erişimi önlemek, iletişim faaliyetlerinin yürütülmesi, inceleme, soruşturma ve denetim
            çalışmalarının yapılabilmesi amacıyla işlenmesi.
          </Text>
        </ListItem>
      </List>
      <Text mt={4}>
        Açık rızanıza ihtiyaç duyulan hallerde kişisel verileriniz, sizler tarafından serbest iradeniz ile sunulan açık
        rızanın kapsamıyla sınırlı olarak işlenecektir. Açık rızanızı dilediğiniz zaman geri alabilme imkânına sahip
        olduğunuzu hatırlatmak isteriz.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        7. Kişisel Verilerinizin Saklanması ve İmhası
      </Heading>
      <Text mt={4}>
        İşbu Gizlilik Politikası ve Aydınlatma Metni'nde belirtilen kişisel verileriniz Kanun'a ve 30224 sayılı ve
        28.10.2017 tarihli Resmi Gazete'de yayımlanarak yürürlüğe giren Kişisel Verilerin Silinmesi, Yok Edilmesi
        veya Anonim Hale Getirilmesi Hakkında Yönetmelik'e uygun olarak saklanacak ve imha edilecektir. Kişisel
        verileriniz, işlenmesini gerektiren sebeplerin ortadan kalkması halinde resen veya talebiniz üzerine
        Şirketimiz tarafından silinir, yok edilir veya anonim hâle getirilir.
      </Text>
      <Text mt={4}>
        Norbi olarak sakladığımız kişisel verilerin güvenliğini Kanun ve ilgili mevzuata uygun olarak sağlamaktayız.
        Bu kapsamda Şirketimiz, işbu Gizlilik Politikası ve Aydınlatma Metni kapsamında işlediği kişisel verilerin
        hukuka uygunluğunu sağlamak, hukuka aykırı olarak işlenmesini ve erişilmesini önlemek ve muhafazasını
        sağlamak için uygun güvenlik önlemlerini almakta ve ihtiyaç görülen güvenlik teknolojilerini bünyesinde
        barındırmaktadır.
      </Text>

      <Heading as="h2" size="md" mt={6}>
        8. Kişisel Verileriniz Konusundaki Haklarınız
      </Heading>
      <Text mt={4}>
        İlgili kişi olarak, Kanun'un 11. maddesi uyarınca aşağıdaki haklara sahipsiniz:
      </Text>
      <List mt={4} spacing={4}>
        <ListItem>
          • Kişisel verilerinizin işlenip işlenmediğini öğrenme,
        </ListItem>
        <ListItem>
          • Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
        </ListItem>
        <ListItem>
          • Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
        </ListItem>
        <ListItem>
          • Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,
        </ListItem>
        <ListItem>
          • Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme ve bu
          kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme,
        </ListItem>
        <ListItem>
          • Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini
          gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya yok edilmesini
          isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini
          isteme,
        </ListItem>
        <ListItem>
          • İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir
          sonucun ortaya çıkmasına itiraz etme,
        </ListItem>
        <ListItem>
          • Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde bu zararın
          giderilmesini talep etme.
        </ListItem>
      </List>

      <Text mt={4}>
        Bu haklarınıza ilişkin taleplerinizi, kimlik tespitinizin yapılmasını sağlayıcı belgeleri de eklemek suretiyle
        <Link href="https://norbi.app/veri-basvuru-formu" isExternal>
          https://norbi.app/veri-basvuru-formu
        </Link>
        bağlantısındaki Veri Sahibi Başvuru Formu'nu doldurarak Veri
        Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ'de yer verilen usul ve esaslar çerçevesinde
        aşağıda bilgilerimize yer verdiğimiz iletişim yöntemlerinden biri üzerinden bize iletebilirsiniz. Başvurunuz
        ücretsiz olarak yanıtlanacaktır. Ancak talep edilen işlemin ayrıca bir maliyet gerektirmesi halinde, Kişisel
        Verileri Koruma Kurulu tarafından belirlenen tarifedeki ücretler talep edilecektir.
      </Text>
      <Text mt={4}>
        Başvurularınız, talebin niteliğine göre mümkün olan en kısa sürede ve en geç otuz (30) gün içinde yazılı
        veya elektronik ortamdan ücretsiz olarak cevaplanacaktır. Ancak, talep edilen işlemin ayrıca bir maliyet
        gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulu tarafından belirlenen tarifedeki ücret talep
        edilecektir. Başvurunun Şirketimiz hatasından kaynaklanması hâlinde alınan ücret tarafınıza iade
        edilecektir. Talebinizin reddedilmesi halinde, başvurunuz gerekçeli olarak yanıtlanacaktır.
      </Text>

      <Heading as="h3" size="sm" mt={6}>
        İLETİŞİM BİLGİLERİ
      </Heading>
      <Text mt={2}>E-posta Adresi: destek@norbiapp.com</Text>
      <Text>Kayıtlı E-posta Adresi (KEP): yenkidobilisim@hs01.kep.tr</Text>
      <Text>Adres: Halaskargazi Cad. No: 38-66E İç Kapı No: 215, Nişantaşı Lotus, 34371 Şişli/İstanbul</Text>
    </Box>
  );
};

export default KvkkPolicy;
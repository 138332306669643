import { Box, Heading, Text } from '@chakra-ui/react';

const ContactUs = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">İletişim</Heading>
      
      <Box mt={6}>
        <Text><strong>Şirket Ünvanı:</strong> Yenkido Bilişim Hizmetleri Ticaret Anonim Şirketi</Text>
      </Box>
      
      <Box mt={4}>
        <Text><strong>Adres:</strong> Halaskargazi Cd. No: 38-66E İç Kapı No:215, Nişantaşı Lotus, 34371 Şişli-İstanbul</Text>
      </Box>
      
      <Box mt={4}>
        <Text><strong>E-posta:</strong> destek@norbiapp.com</Text>
      </Box>

      <Box mt={4}>
        <Text><strong>Telefon:</strong> 0 (212) 939 9272 </Text>
      </Box>
      
      <Box mt={4}>
        <Text><strong>Mersis No:</strong> 0949076075300001</Text>
      </Box>
      
      <Box mt={4}>
        <Text><strong>KEP Adresi:</strong> yenkidobilisim@hs01.kep.tr</Text>
      </Box>
    </Box>
  );
};

export default ContactUs;
import React from "react";
import { Box, Heading, Text, List, ListItem, Link } from "@chakra-ui/react";

const PolicySection = ({ title, children }) => (
  <Box mt={6}>
    <Heading as="h2" size="md" mb={4}>
      {title}
    </Heading>
    {children}
  </Box>
);

const RiskNotification = () => {
  return (
    <Box px={{ base: "5%", lg: 32 }} py={20}>
      <Heading as="h1" mb={4} mt={{ base: 4, lg: 0 }} textAlign="center">
        RİSK AÇIKLAMA BİLDİRİMİ
      </Heading>

      <Text>
        İşbu Risk Açıklama Bildirimi ("Bildirim") Halaskargazi Cd. No: 38-66E İç
        Kapı No:215 Nişantaşı Lotus 34371 Şişli-İstanbul adresinde mukim Yenkido
        Bilişim Hizmetleri Ticaret Anonim Şirketi (“Norbi” “Şirketimiz” veya
        “biz”) aracılığı ile sağlanan internet siteleri (
        <Link href="https://norbi.app" isExternal>
          https://norbi.app
        </Link>
        ,{" "}
        <Link href="https://web.norbi.app" isExternal>
          https://web.norbi.app
        </Link>
        ,{" "}
        <Link href="https://polyverse.life" isExternal>
          https://polyverse.life
        </Link>
        ) ve mobil uygulamalar (bundan sonra hep birlikte “Platform”) için
        geçerlidir ve Kullanıcı ile Norbi arasındaki Kullanıcı Sözleşmesi’nin
        (“Kullanıcı Sözleşmesi”) bir parçası olarak kabul edilecektir.
      </Text>

      <Text mt={4}>
        Burada kullanılan ancak açıkça tanımlanmayan büyük harfli terimler
        Kullanıcı Sözleşmesi’nde sağlanan tanımlara sahip olacaktır.
      </Text>

      <Text mt={4}>
        Platform’a erişerek veya Platform’u kullanarak Kripto Varlıklar’ı içeren
        işlemlere veya Platform’un kullanımına bağlı riskleri kapsamlı bir
        şekilde anladığınızı kabul beyan ve taahhüt edersiniz.
      </Text>

      <Text mt={4}>
        Kripto Varlıklar’ı içeren işlemlerle veya Platform’un kullanımıyla
        bağlantılı risklerin özelliklerini potansiyel değerini
        erişilebilirliğini ve uygunluğunu değerlendirmek münhasıran sizin
        sorumluluğunuzdadır. Şirketimiz herhangi bir Kripto Varlık’ın
        elverişliliği ve uygunluğu ve satın alma stratejileri dahil olmak üzere
        herhangi bir Kripto Varlık’a ilişkin öneri veya tavsiyede bulunmaz.
      </Text>

      <Text mt={4}>
        Platform’a erişim sağladığınızda bunu kendi sorumluluğunuz çerçevesinde
        gerçekleştirdiğinizi ve buna dair tüm risklerin kendi üzerinizde
        olduğunu kabul beyan ve taahhüt etmektesiniz.
      </Text>

      <Text mt={4}>
        İşbu Bildirim genel anlamda Kripto Varlıklar’ın edinimi, elde tutulması,
        saklanması, kullanımı, alım satım işlemleri, Kripto Varlık piyasasında
        yapılan işlemler veya Kripto Varlıklar’la yahut bunlara ilişkin
        yapacağınız diğer işlemlerin risklerine yönelik genel bir bilgilendirme
        niteliği taşımaktadır ve Kripto Varlıklar ve Platform’un kullanımıyla
        ilgili oluşabilecek her riski açıklamaz. Sonuç olarak Platform
        kullanımının kişisel durumunuz ve finansal kaynaklarınızla uyumlu olup
        olmadığını dikkatli bir şekilde sizin değerlendirmeniz gerekmektedir.
      </Text>

      <Text mt={4}>
        Hesabınızdaki Kripto Varlıklar’ı tamamen kaybedebileceğinizi ve belirli
        piyasa koşulları altında bir pozisyonu likidite etmenin zor veya
        imkansız olabileceğini göz önünde bulundurmalısınız.
      </Text>

      <Text mt={4}>
        Nispeten yeni olan ve hızla ilerleyen Blockzincir teknolojisi, Kripto
        Varlıklar’ın değerini etkileyebilir. Bu tür varlıkların ekonomik
        değerinin, yönetişiminin veya işlevselliğinin uzun vadede değişmeden
        kalacağını garanti etmek mümkün değildir.
      </Text>
      <Text mt={4}>
        Kripto Varlıklar’ın fiyatı ve değeri ile potansiyel gelirler çeşitli
        nedenlerle dalgalanmalara maruz kalabilir ve bu da sizin çıkarlarınıza
        ters düşebilir. Tek bir Kripto Varlık ürününün fiyatı düşebilir ve hatta
        bazı durumlarda tüm değerini kaybederek değersiz hale gelebilir.
      </Text>

      <Text mt={4}>
        Kripto Varlık fiyatlandırması, küresel piyasa arz ve talep dinamikleri
        tarafından belirlenir. Sonuç olarak belirli bir Kripto Varlık’a yönelik
        talepteki azalma, buna karşılık gelen bir fiyat düşüşüyle
        sonuçlanacaktır.
      </Text>

      <Text mt={4}>
        Bu sebeple, bir Kripto Varlık’ın uzun vadede değerini koruyacağı garanti
        edilemez. Kripto Varlık alım satımının temel risklerinden biri, bu
        ürünlerin alım satımı yoluyla kâr elde etmek yerine zarara uğrama
        olasılığıdır.
      </Text>

      <Text mt={4}>
        Kripto Varlıklar sınırlı likiditeye sahip olabilir ve bu da bir satış
        emri gönderirken beklediğiniz fiyattan satış yapmayı potansiyel olarak
        imkansız hale getirebilir. Bu durum, hızlı fiyat dalgalanmalarının
        yaşandığı dönemler de dahil olmak üzere her an ortaya çıkabilir.
      </Text>
      <Text mt={4}>
        Kripto Varlık işlemleri geri döndürülemez olabilir; sonuç olarak hileli
        veya istenmeyen işlemlerden kaynaklanan kayıplar telafi edilemez
        olabilir ve geri alınamayabilir.
      </Text>

      <Text mt={4}>
        Belirli Kripto Varlık işlemleri, halka açık bir deftere kaydedildikten
        sonra tamamlanmış sayılır ve bu işlemi başlattığınız tarih veya saatle
        aynı zamana denk gelmeyebilir.
      </Text>
      <Text mt={4}>
        Kripto Varlık değerleri, genellikle piyasa katılımcılarının itibari para
        birimlerini veya diğer Kripto Varlıklar’ı takas etmeye yönelik devam
        eden istekliliğine bağlıdır. Bu durum, piyasasının ortadan kalkması
        halinde belirli bir Kripto Varlık için kalıcı ve tam bir değer kaybına
        yol açabilir.
      </Text>

      <Text mt={4}>
        Kripto Varlıklar’ın doğası, artan bir dolandırıcılık veya siber saldırı
        riskine yol açabilir ve bu da Platform’un yaşadığı teknolojik
        zorlukların Kripto Varlıklar’a erişimi veya bunların kullanımının
        engelleyebileceği anlamına gelebilir. Kripto Varlıklar, varlıklara siber
        saldırı düzenlenmesi, çalınması, çeşitli güvenlik ihlallerine maruz
        kalması, piyasaya erişimin kısıtlanması ve piyasa performansının
        engellenmesi veya önlenmesi, elektronik veya teknolojik arızalar
        yaşanması gibi riskleri barındırır. Blokzincir teknolojisi ve Kripto
        Varlıklar, bunların temelini oluşturan yazılımla birlikte, kötü amaçlı
        yazılım saldırıları, hizmet reddi saldırıları, mutabakata dayalı
        saldırılar, smurfing ve spoofing dahil olmak üzere üçüncü taraf siber
        saldırılarına veya hack'lerine karşı savunmasız olabilir. Bu tür
        saldırılar Kripto Varlıklar’ın çalınmasına veya kaybedilmesine yol
        açabilir.
      </Text>

      <Text mt={4}>
      Norbi; Kripto Varlık alım-satım işlemlerinize sadece aracılık etmekte olup, herhangi bir Kripto Varlığın ihraççısı, işleticisi, yöneticisi veya herhangi bir şekilde hak sahibi değildir.
      </Text>
      <Text mt={4}>
      Kripto Varlık ticareti, irrasyonel veya rasyonel piyasa balonlarına veya güvenin azalmasına karşı savunmasız olabilir ve bu da potansiyel olarak arzla karşılaştırıldığında talepte bir çöküşe neden olabilir. Sistemin anonimliği tehlikeye girerse, fonlar kaybolur veya çalınırsa ya da bilgisayar korsanları veya devlet kurumları işlemlerin tamamlanmasını engellerse, bir Kripto Varlık’a olan güven tehlikeye girebilir.
      </Text>

      <Text mt={4}>
      Kripto Varlıklar’ın yasal düzenlenmesi, birçok yargı alanında hala gelişme aşamasındadır. Ulusal veya uluslararası düzeyde yasal ve düzenleyici değişiklikler veya eylemler Kripto Varlıklar’ın kullanımını, transferini, değişimini ve değerini olumsuz etkileyebilir. Yürürlükteki yasaların değişmesi nedeniyle Platform kullanılamayabilir. Platform’un artık size erişilir olmaması sebebiyle zarara uğrayabilir veya yükümlülük altına girebilirsiniz. Herhangi bir sistem arızasının sonucu, emrinizin uygunsuz bir şekilde yerine getirilmesi veya tamamen yerine getirilmemesi olabilir. Ayrıca, internetin tamamen güvenilir bir iletim ortamını garanti etmediğini ve bunun da hizmet sunumunda gecikmelere yol açabileceğini unutmayın.
      </Text>
      <Text mt={4}>
      Şirketimiz, Platform üzerinden gerçekleştirilen işlemler veya faaliyetlerle ilgili olarak bir  danışman olarak işlev görmez ve size karşı herhangi bir güven ilişkisi veya yükümlülüğü yoktur. Platform üzerindeki işlemlerinizin finansal amaç ve hedeflerinizle uyumunu izlemediğimiz gibi Platform’un kullanımına ilişkin finansal tavsiye veya önerilerde de bulunmayız. Platform’da gerçekleştirilen işlemler için finansal kaynaklarınızın yeterliliğini, ilgili riskleri ve satın alma kararlarınızın finansal hedeflerinizle tutarlılığını değerlendirmek sizin sorumluluğunuzdadır.
      </Text>

      <Text mt={4}>
      Kripto Varlıklar’ın satışından ve diğer gelirlerden elde edilen gelirlerin, kaynakta stopaj yoluyla alınan vergiler de dahil olmak üzere, pazardaki yetkililer tarafından uygulanan vergi, harç veya diğer ücret veya harçlara tabi olabileceğini özellikle göz önünde bulundurmanız gerekmektedir. 
      </Text>

      <Text mt={4}>
      Platform’un sağlanması esnasında ödeme sağlayıcıları, bankalar ve diğer üçüncü taraflar ile çalışabiliriz. Bu durumlarda, ilgili üçüncü tarafların kullanıcı sözleşmesine de tabi olursunuz ve bu tür üçüncü tarafların neden olabileceği herhangi bir zarardan ötürü Şirket'in sorumlu olmayacağı riskini kabul edersiniz.
      </Text>


      <Text mt={4}>
      Yazılım ve internet bağlantı hatası dahil, ancak bunlarla sınırlı olmamak kaydıyla, internet tabanlı bir alım satım sisteminin kullanılmasıyla ilişkili riskler mevcut olup, bu hususu bilmeniz önemlidir. Size sunulan Hizmetler’i kullanırken hangi nedenle olursa olsun karşılaşabileceğiniz iletişim arızalarından, kesintilerinden, hatalardan, aksaklıklardan veya gecikmelerden Norbi sorumluluk kabul etmemektedir.
      </Text> 

      <Text mt={4}>
      Kripto Varlık transfer işlemleriniz gerçekleştirildikten sonra iade edilemez ve geri döndürülemez. Bu nedenle, transferi gerçekleştireceğiniz cüzdan adresinin doğruluğunu kontrol etmek sizin yükümlülüğünüzdedir.
      </Text>

      <Text mt={4}>
      Kullanıcılar, Kripto Varlık işlemleri gerçekleştirirken hukuka uygun davranmakla yükümlüdürler ve Norbi, Kripto Varlık işlemlerine ilişkin sorumluluk kabul etmemektedir. 
      </Text>

      <Text mt={4}>
      İşbu Bildirim, Kullanıcıları genel olarak mevcut riskler hakkında bilgilendirmeyi amaçlamaktadır ve Kripto Varlıklar’a ilişkin işlemlerden ve uygulamadan kaynaklanabilecek tüm riskleri kapsamayabilir. Burada belirtilen risklerin dışında da Kripto Varlıklar ve ilgili işlemlerin yüksek riskli ve spekülatif olduğunu ve her zaman farklı riskler ile karşılaşma ihtimaliniz olduğunu göz önünde bulundurmanız, dolayısıyla işlem yapmadan önce dikkatli bir şekilde araştırma yapmanız gerekmektedir.
      </Text>

      <Text mt={4}>
      İşbu Bildirim, Norbi ile imzaladığınız/onayladığınız Kullanıcı Sözleşmesi'nin eki ve ayrılmaz bir parçasıdır. 
      </Text>
    </Box>
  );
};

export default RiskNotification;

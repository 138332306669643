import React from "react";
import { ChakraProvider, Box, VStack, useMediaQuery } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import theme from "./styles/theme";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import Prices from "./pages/Prices";

import CookiePolicy from "./docs/CookiePolicy";
import RiskNotification from "./docs/RiskNotification";
import LimitsAndCommissions from "./docs/LimitAndComissions";
import TradeRegistryInfo from "./docs/TradeRegistryInfo";
import AmlKycPolicy from "./docs/AmlKycPolicy";
import KvkkPolicy from "./docs/KvkkPolicy";
import AboutUs from "./docs/AboutUs";
import ContactUs from "./docs/ContactUs";
import MediaAndPress from "./pages/MediaAndPress/MediaAndPress";
import UserAgreement from "./docs/UserAgreement";
import Commissions from "./docs/Commissions";
import Limit from "./docs/Limit";
import Security from "./docs/Security";
import RiskStatement from "./docs/RiskStatement";
import RusvetYolsuzlukMucadele from "./docs/RusvetYolsuzlukMucadele";
import KendiAdinaBaskasiHesabinaIslem from "./docs/KendiAdinaBaskasiHesabinaIslem";
import VeriBasvuruFormu from "./docs/VeriSahibiBasvuruFormu";
import PrivacyPolicy from "./docs/PrivacyPolicy";

// Diğer PDF sayfalarını da ekleyin
// import KullaniciSozlesmesiPage from "./pages/KullaniciSozlesmesiPage";

function App() {
  const [isSmallScreen] = useMediaQuery("(max-width: 30em)");

  return (
    <ChakraProvider theme={theme}>
      <Box bg="#111" color="white" minH="100vh">
        <VStack
          bg="background.default"
          overflowX="hidden"
          spacing={0}
          align="stretch"
          minH="100vh"
          overflowY="auto"
          pt={isSmallScreen ? "50px" : "100px"}
        >
          <Header />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/prices" element={<Prices />} />
            <Route path="/cerez-politikasi" element={<CookiePolicy />} />
            <Route path="/aml-kyc" element={<AmlKycPolicy />} />
            <Route path="/risk-bildirimi" element={<RiskNotification />} />
            <Route
              path="/limit-ve-komisyonlar"
              element={<LimitsAndCommissions />}
            />
            <Route
              path="/ticaret-sicil-bilgileri"
              element={<TradeRegistryInfo />}
            />
            <Route path="/tr/legal" element={<KvkkPolicy />} />
            <Route path="/hakkimizda" element={<AboutUs />} />
            <Route path="/iletisim" element={<ContactUs />} />
            <Route path="/kullanici-sozlesmesi" element={<UserAgreement />} />
            <Route path="/medya-ve-basin" element={<MediaAndPress />} />
            <Route path="/komisyonlar" element={<Commissions />} />
            <Route path="/limit" element={<Limit />} />
            <Route path="/guvenlik" element={<Security />} />
            <Route path="/risk-istahi-bildirimi" element={<RiskStatement />} />
            <Route
              path="/rusvet-ve-yolsuzlukla-mucadele-politikasi"
              element={<RusvetYolsuzlukMucadele />}
            />
            <Route
              path="/baskasi-hesabina-islem-yapanlara-iliskin-duyuru"
              element={<KendiAdinaBaskasiHesabinaIslem />}
            />
            <Route path="/veri-basvuru-formu" element={<VeriBasvuruFormu />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            {/* Diğer PDF sayfalarını da ekleyin */}
            {/* <Route path="/kullanici-sozlesmesi" element={<KullaniciSozlesmesiPage />} /> */}
          </Routes>

          <Footer />
        </VStack>
      </Box>
    </ChakraProvider>
  );
}

export default App;
